import { Box, Pagination, PaginationItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import { PAGE_LIMIT } from "../../utils/constants";

interface IPagination {
	page: number;
	count: number | undefined;
	rowCount: number;
	onPageChange: (
		event: React.ChangeEvent<unknown> | null,
		newPage: number,
		newPageSize?: number
	) => void;
}

const CustomPagination: React.FC<IPagination> = ({
	count,
	page,
	rowCount,
	onPageChange,
}): JSX.Element => {
	// console.log("count", count);
	// console.log("page", page);
	// console.log("rowCount", rowCount);
	const [state, setState] = useState(3);
	useEffect(() => {
		const handleScroll = () => {
			if (window.innerWidth < 500) {
				setState(3);
			} else if (window.innerWidth > 500) {
				setState(10);
			}
		};
		handleScroll();

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	let pageSize = parseInt(PAGE_LIMIT);
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				marginTop: "35px",
			}}
		>
			{rowCount > pageSize && (
				<Stack spacing={1}>
					<Pagination
						count={count}
						variant="outlined"
						shape="rounded"
						color="primary"
						defaultPage={1}
						showFirstButton={true}
						showLastButton={true}
						onChange={onPageChange}
						page={page}
						renderItem={(item) => (
							<PaginationItem
								sx={{
									color: item.selected
										? "red"
										: theme.palette.secondary.light,
									m: { xs: "2px", md: 1 },
								}}
								{...item}
								color="primary"
								size="small"
								slots={{
									first: () => <div>{`<<`}</div>,
									last: () => <div>{`>>`}</div>,
								}}
							/>
						)}
					/>
				</Stack>
			)}
		</Box>
	);
};
export default CustomPagination;
