import { blue, danger, success, warning } from "./color";
import { PAGE_LIMIT } from "./constants";

export const saveInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getColorCode = (value: number) => {
  if (value === 10) {
    return danger;
  } else if (value > 5) {
    return warning;
  } else if (value > 1) {
    return blue;
  } else {
    return success;
  }
};
export const getOffset = (page: number) => {
  const offset = (
    parseInt(PAGE_LIMIT) * page -
    parseInt(PAGE_LIMIT)
  ).toString();
  return offset;
};
export const getDateMonth = (value: any): string => {
  if (!value || !value.length) {
    return "";
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = new Date(value);
  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};
export const getFormattedDate = (value: any): string => {
  if (!value || !value.length) {
    return "";
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date(value);
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

export const getMatchSnapsKeys = (snapRefs: Array<any>) => {
  let snaps: Array<number> = [];
  snapRefs.forEach((item: any) => {
    let key: string = Object.keys(item)[0];
    snaps.push(Number(key));
  });
  return snaps;
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatPhoneNumber = (phoneNumber: string) => {
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  const match = digitsOnly.match(/(\d{3})(\d{3})(\d{4})/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

export const columnHeader = [
  "listing_title*",
  "description*",
  "minimum_bid*",
  "deliverable*",
  "images*",
  "inventory_location*",
];

export const toCapitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export function isValidDecimalNumber(value: string): boolean {
  const decimalRegex = /^\d+(\.\d{1,2})?$/;
  const match = value.match(decimalRegex);
  return match !== null && match[0] === value;
}
export const FileType = {
  // Doc: 'doc',
  Pdf: "pdf",
  Mp3: "mp3",
  Amr: "amr",
} as const;
export const getMimeType = (type: string) => {
  // if (type === "application/msword") {
  //     return FileType.Doc;
  // } else
  if (type === "audio/mpeg") {
    return FileType.Mp3;
  } else if (type === "application/pdf") {
    return FileType.Pdf;
  }
  return FileType.Mp3;
};
