import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { TagUpdateRequest } from "../openapi";
import { BulkTagCreateRequest } from "../openapi/models/bulk-tag-create-request";
import { TagCreateRequest } from "../openapi/models/tag-create-request";
import { clearModalName } from "../redux/features/modal/modalSlice";
import {
  clearTagDetils,
  editTag,
  storeDeleteStatus,
  storePaginationDetails,
  storeTags,
} from "../redux/features/tags/tagsSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getTagService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useTag = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { tag } = useSelector((state: RootState) => state.tag);

  const getTagList = async (page?: number) => {
    if (accessToken) {
      setIsLoading(true);
      if (page) {
        let offset = getOffset(page);
        try {
          const service = await getTagService(accessToken);
          const response = await service.getTags(PAGE_LIMIT, offset);
          if (response.status === 200 || response.status === 201) {
            dispatch(storeTags(response.data.tags));
            dispatch(storePaginationDetails(response.data));
            setIsLoading(false);
          }
        } catch (error: any) {
          setIsLoading(false);
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      } else {
        try {
          const service = await getTagService(accessToken);
          const response = await service.getTags("1000", "0");
          if (response.status === 200 || response.status === 201) {
            dispatch(storeTags(response.data.tags));
            dispatch(storePaginationDetails(response.data));
            setIsLoading(false);
          }
        } catch (error: any) {
          setIsLoading(false);
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      }
    }
  };
  const createTag = async (value: string) => {
    if (accessToken && value) {
      setCreateLoading(true);
      const createRequest: TagCreateRequest = {
        title: value,
      };
      try {
        const service = await getTagService(accessToken);
        const response = await service.createtag(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keyword created successfully", {
            variant: "success",
          });
          setCreateLoading(false);
          //dispatch(createTags(true));
          getTagList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setCreateLoading(false);
      }
    }
  };
  const updateTag = async (values: any) => {
    if (accessToken && tag) {
      setIsLoading(true);
      const updateRequest: TagUpdateRequest = {
        id: tag.id,
        title: values.title,
      };
      try {
        const service = await getTagService(accessToken);
        const response = await service.updateTag(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keyword updated successfully", {
            variant: "success",
          });
          setIsLoading(false);
          dispatch(editTag(updateRequest));
          dispatch(clearModalName());
          dispatch(clearTagDetils());
          getTagList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };
  const deleteTag = async () => {
    if (accessToken && tag) {
      setIsLoading(true);
      try {
        const productService = await getTagService(accessToken);
        const response = await productService.deletetag(tag.id);
        if (response.status === 200) {
          enqueueSnackbar("Keyword deleted successfully", {
            variant: "success",
          });
          getTagList(1);
          setIsLoading(false);
          dispatch(storeDeleteStatus(true));
          dispatch(clearModalName());
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  const createBulkTags = async (values: any) => {
    if (accessToken && values) {
      const tags = values.keywords.split(",").map((tag: string) => tag.trim());
      const createRequest: BulkTagCreateRequest = {
        tags: tags,
      };
      try {
        const service = await getTagService(accessToken);
        const response = await service.createBulkTags(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Keywords created successfully", {
            variant: "success",
          });
          getTagList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
      }
    }
  };

  return {
    getTagList,
    createTag,
    isLoading,
    createLoading,
    updateTag,
    deleteTag,
    createBulkTags,
  };
};
export default useTag;
