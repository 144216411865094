import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { DatasetCreateRequest } from "../openapi";
import { DatasetUpdateReq } from "../openapi/models/dataset-update-req";
import {
  clearDatasetDetils,
  editDataset,
  saveLoading,
  storeDatasetDetails,
  storeDatasets,
  storePaginationDetails,
} from "../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../redux/features/modal/modalSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getDatasetService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useDataset = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { dataset } = useSelector((state: RootState) => state.dataset);

  const getDatasetList = async (page: number) => {
    let offset = getOffset(page);
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.getAdminList(PAGE_LIMIT, offset);
        if (response.status === 200 || response.status === 201) {
          dispatch(storeDatasets(response.data.datasets));
          dispatch(storePaginationDetails(response.data));
          dispatch(saveLoading(false));
        }
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const getDatasetById = async (id: number) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.getDataset(id)
        if (response.status === 200 || response.status === 201) {
          dispatch(storeDatasetDetails(response.data));
          dispatch(saveLoading(false));
          return response.data;
        }
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const createContent = async (value: any) => {
    if (accessToken && value) {
      dispatch(saveLoading(true));
      const createRequest: DatasetCreateRequest = {
        bodyText: value.bodyText,
        link: value.link,
        tagId: value.tagId,
      };
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.createDataset(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content created successfully", {
            variant: "success",
          });
          dispatch(saveLoading(false));
          //dispatch(createTags(true));
          getDatasetList(1);
          return response.data;
        }
      } catch (error: any) {
        dispatch(saveLoading(false));

        enqueueSnackbar(error?.response?.data?.message.toString() ?? error.toString(), {
          variant: "error",
        });
      }
    }
  };
  const updateDataset = async (values: any) => {
    if (accessToken && dataset) {
      dispatch(saveLoading(true));
      const updateRequest: DatasetUpdateReq = {
        id: dataset.id,
        bodyText: values.bodyText,
        tagId: values.tagId,
        link: values.link,
        title: values.title,
        summary: values.summary
      };
      try {
        const service = await getDatasetService(accessToken);
        const response = await service.updateTag(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Content updated successfully", {
            variant: "success",
          });
          dispatch(saveLoading(false));
          dispatch(editDataset(updateRequest));
          getDatasetList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };
  const deleteDataset = async () => {
    if (accessToken && dataset) {
      dispatch(saveLoading(true));
      try {
        const productService = await getDatasetService(accessToken);
        const response = await productService.deleteDataset(dataset.id);
        if (response.status === 200) {
          enqueueSnackbar("Content deleted successfully", {
            variant: "success",
          });
          getDatasetList(1);
          dispatch(saveLoading(false));
          dispatch(clearModalName());
          dispatch(clearDatasetDetils());
          return response;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };

  return {
    createContent,
    getDatasetList,
    updateDataset,
    deleteDataset,
    getDatasetById,
  };
};
export default useDataset;
