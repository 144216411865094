export const LS_EMAIL = "email";
export const ADMIN_INFO = "adminInfo";
export const UPLOAD_FILE_MODAL = "UPLOAD_FILE_MODAL";
export const PAGE_LIMIT = "12";
export const USER_ADDRESS = "USER_ADDRESS";
export const CATEGORY_MODAL = "CATEGORY_MODAL";
export const CONTENT_MODAL = "CONTENT_MODAL";
export const TAG_MODAL = "TAG_MODAL";
export const DELETE_CATEGORY_MODAL = "DELETE_CATEGORY_MODAL";
export const DELETE_CONTENT_MODAL = "DELETE_CONTENT_MODAL";
export const DELETE_TAG_MODAL = "DELETE_TAG_MODAL";
export const DELETE_FAQ_MODAL = "DELETE_FAQ_MODAL";
export const READ_MORE_DATASET = "READ_MORE_DATASET";
export const BULK_TAG_MODAL = "BULK_TAG_MODAL";
export const FAQ_MODAL = "FAQ_MODAL";
