import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, useMediaQuery } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useCategory from "../../hooks/useCategory";
import { storeCategoryDetails } from "../../redux/features/categories/categoriesSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { CATEGORY_MODAL, DELETE_CATEGORY_MODAL } from "../../utils/constants";
import DeleteCategoryModal from "../modals/DeleteCategoryModal";
import { TableTemplate } from "../table/TableTemplate";
import { CategoryDataGrid } from "./CategoryDataGrid";

const CategoriesList = () => {
  const dispatch = useDispatch();
  const { getCategoryList, isLoading } = useCategory();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { categories, pagination } = useSelector(
    (state: RootState) => state.category
  );
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleEditClick = (params: GridRowParams) => {
    dispatch(storeCategoryDetails(params.row));
    dispatch(saveModalName(CATEGORY_MODAL));
  };
  const handleDelete = (params: GridRowParams) => {
    dispatch(storeCategoryDetails(params.row));
    dispatch(saveModalName(DELETE_CATEGORY_MODAL));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      flex: isSm ? 0.3 : undefined,
      width: isXs ? 100 : undefined,
    },
    {
      field: "title",
      headerName: "Name",
      sortable: false,
      flex: isSm ? 0.7 : undefined,
      width: isXs ? 180 : undefined,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CreateOutlinedIcon fontSize="small" />}
          label="Edit"
          onClick={() => handleEditClick(params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon fontSize="small" />}
          label="Delete"
          onClick={() => handleDelete(params)}
          showInMenu
        />,
      ],
    },
  ];
  let searchedListers: any[] = [];
  const handleSearch = async (value: string) => {
    setSearchValue(value);
  };
  useEffect(() => {
    getCategoryList(page);
  }, [page]);
  return (
    <Box pt={3}>
      <TableTemplate
        // search={
        //   <SearchInput
        //     searchCallback={handleSearch}
        //     searchLabel={`Search Team Members...`}
        //   />
        // }
        list={categories}
        dataGrid={
          <CategoryDataGrid
            paginationData={pagination}
            list={categories}
            columns={columns}
            page={page}
            handlePageChange={handlePageChange}
          />
        }
        buttonGroups={null}
        emptyView={<EmptyListAnimation title="No category found" />}
        loading={isLoading}
      />
      <DeleteCategoryModal />
    </Box>
  );
};
export default CategoriesList;
