import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FaqList } from "../../../openapi/models/faq-list";
import { FaqResponse } from "../../../openapi/models/faq-response";
import { FaqUpdateRequest } from "../../../openapi/models/faq-update-request";

export interface FaqState {
  faqs: Array<FaqResponse> | undefined;
  pagination: FaqList | undefined;
  faq: FaqResponse | undefined;
  isFaqLoading: boolean;
  page: number;
}

const initialState: FaqState = {
  faq: undefined,
  pagination: undefined,
  faqs: undefined,
  isFaqLoading: false,
  page: 1,
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    storeFaqs: (state, action: PayloadAction<Array<FaqResponse>>) => {
      state.faqs = action.payload;
    },
    editFaq: (state, action: PayloadAction<FaqUpdateRequest | FaqResponse>) => {
      state.faqs =
        state.faqs &&
        state.faqs?.map((faq: FaqResponse) =>
          faq.id === action.payload.id ? { ...faq, ...action.payload } : faq
        );
    },
    storeFaqDetails: (state, action: PayloadAction<FaqResponse>) => {
      state.faq = action.payload;
    },
    storePaginationDetails: (state, action: PayloadAction<FaqList>) => {
      state.pagination = action.payload;
    },
    clearFaqDetils: (state) => {
      state.faq = undefined;
    },
    saveLoading: (state, action: PayloadAction<boolean>) => {
      state.isFaqLoading = action.payload;
    },
    savePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  storePaginationDetails,
  saveLoading,
  storeFaqDetails,
  storeFaqs,
  clearFaqDetils,
  editFaq,
  savePage,
} = faqSlice.actions;

export default faqSlice.reducer;
