import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, Chip, useMediaQuery } from "@mui/material";
import {
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useDataset from "../../hooks/useDataset";
import { TagResponse } from "../../openapi";
import { storeDatasetDetails } from "../../redux/features/datasets/datasetsSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { CONTENT_MODAL, DELETE_CONTENT_MODAL } from "../../utils/constants";
import DeleteContentModal from "../modals/DeleteContentModal";
import { TableTemplate } from "../table/TableTemplate";
import { TeamDataGrid } from "./UserDataGrid";
import useCustomer from "../../hooks/useCustomer";

const UsersList = () => {
	const { getCustomersList, isLoading } = useCustomer();
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));
	//const [searchValue, setSearchValue] = useState<string | null>(null);
	const [page, setPage] = useState(1);
	const { customers, pagination } = useSelector(
		(state: RootState) => state.customer
	);
	const handlePageChange = (event: any, newPage: any) => {
		setPage(newPage);
	};

	// congnitoUserId: "6db9403a-9985-420a-afe0-cfd814a1e242";
	// email: "ceo@kuna.life";
	// firstName: "Juan josé";
	// id: 180;
	// stripeCustomerId: "cus_PrHDY9YCUFyBwQ";
	// subscriptionType: "Free";
	// userType: "customer";
	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID",
			sortable: false,
			flex: isSm ? 0.2 : undefined,
			width: isXs ? 70 : undefined,
		},
		{
			field: "email",
			headerName: "Email",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "firstName",
			headerName: "Name",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "subscriptionType",
			headerName: "Subscription Type",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "stripeCustomerId",
			headerName: "Stripe Customer ID",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		// {
		// 	field: "congnitoUserId",
		// 	headerName: "Cognito User ID",
		// 	sortable: false,
		// 	flex: isSm ? 0.7 : undefined,
		// 	width: isXs ? 180 : undefined,
		// },
	];
	// let searchedListers: any[] = [];
	// const handleSearch = async (value: string) => {
	//   setSearchValue(value);
	// };
	useEffect(() => {
		getCustomersList(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);
	return (
		<Box pt={3}>
			<TableTemplate
				list={customers}
				dataGrid={
					<TeamDataGrid
						paginationData={pagination}
						list={customers}
						columns={columns}
						page={page}
						handlePageChange={handlePageChange}
					/>
				}
				buttonGroups={null}
				emptyView={<EmptyListAnimation title="No content found" />}
				loading={isLoading}
			/>
		</Box>
	);
};
export default UsersList;
