import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { FC } from "react";
import CustomPagination from "../pagination/CustomPagination";

interface Props {
  paginationData: any;
  list: any;
  columns: GridColDef[];
  page: number;
  handlePageChange: (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
    newPageSize?: number
  ) => void;
}
export const TagDataGrid: FC<Props> = ({
  paginationData,
  list,
  columns,
  page,
  handlePageChange,
}) => {
  return (
    <Box m={4}>
      <DataGrid
        rows={list}
        columns={columns}
        pagination
        autoHeight
        disableColumnMenu
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,":
            {
              outline: "none !important",
            },
          ".MuiDataGrid-columnSeparator--sideRight": {
            display: "none !important",
          },
        }}
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 12 } },
        }}
        components={{
          Pagination: () => (
            <CustomPagination
              count={paginationData?.totalPage}
              page={page}
              rowCount={paginationData.total || list?.length}
              onPageChange={handlePageChange}
            />
          ),
        }}
      />
    </Box>
  );
};
