import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import {
  saveLoading,
  storeCustomers,
  storePaginationDetails,
} from "../redux/features/customers/cutomersSlice";
import { getUserService } from "../utils/apiHelpers";
import { getOffset } from "../utils";
import { PAGE_LIMIT } from "../utils/constants";

const useCustomer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();

  const getCustomersList = async (page?: number) => {
    let offset = "0"
    if (accessToken) {
      if (page) {
        offset = getOffset(page);
      }
      setIsLoading(true);
      saveLoading(true);
      try {
        const service = await getUserService(accessToken);
        const response = await service.getCustomers(page ? PAGE_LIMIT : "1000", offset);
        if (response.status === 200 || response.status === 201) {
          // console.log('users', response.data.users)
          dispatch(storeCustomers(response.data.users));
          dispatch(storePaginationDetails(response.data));
          setIsLoading(false);
          saveLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        saveLoading(false);
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };

  return {
    getCustomersList,
    isLoading,
  };
};

export default useCustomer;
