import { Button } from "@mui/material";
import React from "react";
import { theme } from "../../theme";

interface SECONDARY_OUTLINE_BUTTON_PROPS {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large" | undefined;
  fullWidth?: boolean;
}

const SecondaryOutlineButton: React.FC<SECONDARY_OUTLINE_BUTTON_PROPS> = (
  props
) => {
  const { text, icon } = props;
  const Wrapper = {
    color: theme.palette.secondary.light,
    textTransform: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    borderColor: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.light,
    },
  };
  return (
    <Button
      startIcon={icon}
      variant="outlined"
      {...props}
      disableElevation
      disableRipple
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      sx={{ ...Wrapper, ...props.style }}
      size={props.size}
      fullWidth={props.fullWidth}
    >
      {text}
    </Button>
  );
};

export default SecondaryOutlineButton;
