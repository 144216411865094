import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { IResetPasswordStep1Form } from "../../interfaces/IResetPasswordStep1Form";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";
import FormHeader from "./FormHeader";

const ResetPasswordStep1Form = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useAlert();
  const { loading, error, setError, getResetPasswordCodeByEmail } = useAuth();

  const resetPasswordInitialValue: IResetPasswordStep1Form = {
    email: "",
  };

  const [initialValues, setInitialValues] = useState(resetPasswordInitialValue);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required().label("Email"),
  });

  const onSubmit = async (
    values: IResetPasswordStep1Form,
    actions: FormikHelpers<IResetPasswordStep1Form>
  ) => {
    try {
      actions.setSubmitting(true);
      setInitialValues(values);
      await getResetPasswordCodeByEmail(values);
    } catch (error: any) {
      showSnackbar(error?.message, error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSignInClick = () => {
    navigate("/login");
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormWrapper>
        <FormHeader title="Reset Password" />
        {!loading && error && <FormAlert error={error} setError={setError} />}
        <Typography mb={3} variant="h1" fontSize={'35px'} fontWeight={'700'} color={'#333F4E'}>Reset Password</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => {
            return (
              <Form style={{ display: "flex", flexDirection: "column" }}>
                <FormField name="email" label="Email" placeholder="Email" />
                <FormButton label={"Submit"} loading={loading} />
                <Typography textAlign="center" mt={2}>
                  Back to
                  <Typography
                    mx={1}
                    color="primary"
                    display="inline-block"
                    onClick={handleSignInClick}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Log In
                  </Typography>
                  page
                </Typography>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Box>
  );
};

export default ResetPasswordStep1Form;
