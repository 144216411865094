import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface IProps {
  title: string;
  heading: string;
  status: string;
  type: string;
}
export interface ModalState {
  modalName: string | null;
  statusModalOpen: string | null;
  transactionModal: string | null;
  modalProps: IProps | undefined;
  type: string | null;
}

const initialState: ModalState = {
  modalName: null,
  statusModalOpen: null,
  modalProps: undefined,
  type: null,
  transactionModal: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    saveModalName: (state, action: PayloadAction<string>) => {
      state.modalName = action.payload;
    },
    clearModalName: (state) => {
      state.modalName = null;
    },
  },
});

export const { saveModalName, clearModalName } = modalSlice.actions;

export default modalSlice.reducer;
