import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useFaq from "../../hooks/useFaq";
import { TagResponse } from "../../openapi";
import { clearFaqDetils } from "../../redux/features/faq/faqSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { FAQ_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate from "./ModalTemplate";

const FaqModal = () => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthContext();
  const { updateFaq, createFaq } = useFaq();
  const { modalName } = useSelector((state: RootState) => state.modal);
  const { faq } = useSelector((state: RootState) => state.faq);
  let initialValues = {
    question: "",
    score: 0,
    order: 1,
  };
  if (faq) {
    initialValues = {
      question: faq.question,
      score: faq.score,
      order: faq.order,
    };
  }
  const validationSchema = Yup.object().shape({
    question: Yup.string().min(5).required().label("Question"),
    score: Yup.number().positive().integer().required().label("Score"),
    order: Yup.number().positive().integer().required().label("Order"),
  });
  const handleClose = () => {
    dispatch(clearModalName());
    dispatch(clearFaqDetils());
  };
  const onSubmit = (values: any, actions: any) => {
    addFaq(values, actions);
  };

  const addFaq = async (values: any, actions: any) => {
    if (!accessToken) return;
    let tagIds: any[] = [];
    actions.setSubmitting(true);
    if (values.keywords && values.keywords.length) {
      values.keywords.map((item: TagResponse) => {
        tagIds.push(item.id);
      });
    }
    let request = {
      question: values.question,
      score: values.score,
      order: values.order,
    };
    if (faq) {
      updateFaq(request).then((r: unknown) => {
        const data = r as unknown as any;
        if (data) {
          actions.setSubmitting(false);
          handleClose();
        }
      });
    } else {
      createFaq(request).then((r: unknown) => {
        const data = r as unknown as any;
        if (data) {
          actions.setSubmitting(false);
          handleClose();
        }
      });
    }
  };

  return (
    <ModalTemplate
      openModal={modalName === FAQ_MODAL}
      title={faq ? "Edit Question" : "Add Question"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <FormTextArea
                    name={"question"}
                    label={"Question"}
                    placeholder="Enter question"
                  />
                  <Box mb={3}>
                    <FormField
                      name={"score"}
                      label={"Score"}
                      placeholder="Enter score"
                    />
                  </Box>
                  <Box mb={3}>
                    <FormField
                      name={"order"}
                      label={"Order"}
                      placeholder="Enter order"
                    />
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <PrimaryButton
                      text={faq ? "Save" : "Add New"}
                      style={{ width: "100%" }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    />
                    <SecondaryOutlineButton
                      style={{ width: "100%" }}
                      text="Close"
                      onClick={handleClose}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default FaqModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  color: "#2C3659",
  lineHeight: "21px",
  marginBottom: "5px",
}));

const ErrorMessageWapper = styled(Box)(({ theme }) => ({
  color: "#F2323F",
  fontSize: "12px",
  //paddingLeft: "12px",
  fontWeight: 400,
  marginTop: "5px",
}));
