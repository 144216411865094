import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDataset from "../../hooks/useDataset";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { DELETE_CONTENT_MODAL } from "../../utils/constants";
import ErrorButton from "../buttons/ErrorButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import ModalTemplate from "./ModalTemplate";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
  },
}));
const DeleteContentModal = () => {
  const dispatch = useDispatch();
  const { deleteDataset } = useDataset();
  const { modalName } = useSelector((state: RootState) => state.modal);
  const [isSubmitting, setIsSubmitting] = useState<Boolean>(false);
  const { dataset } = useSelector((state: RootState) => state.dataset);
  const handleClose = () => {
    dispatch(clearDatasetDetils());
    dispatch(clearModalName());
  };
  let heading = "";
  let description = "";
  if (dataset) {
    heading = "Delete content";
    description = "Are you sure you want to delete this content?";
  }
  const handleDeleteProduct = async () => {
    setIsSubmitting(true);
    deleteDataset().then((r: unknown) => {
      const data = r as unknown as any;
      if (data) {
        setIsSubmitting(false);
        handleClose();
      }
    });
  };
  return (
    <ModalTemplate
      openModal={modalName === DELETE_CONTENT_MODAL}
      //maxWidth="500px"
      //maxHeight="230px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Wrapper>
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={"center"}
            mt={2}
          >
            <Typography variant="h6" align="center" mb={1}>
              {heading}
            </Typography>
            <Typography variant="body1" align="center">
              {description}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"center"}
            margin="auto"
            gap={2}
            pt={4}
          >
            <ErrorButton
              text={"Yes, Delete"}
              disabled={isSubmitting ? true : false}
              onClick={handleDeleteProduct}
              icon={
                isSubmitting ? (
                  <CircularProgress size="0.6rem" color="inherit" />
                ) : null
              }
            />

            <SecondaryOutlineButton
              text={"Cancel"}
              disabled={isSubmitting ? true : false}
              onClick={handleClose}
            />
          </Box>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default DeleteContentModal;
