import React from 'react'

const LogoIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.2133 11.5013L35.2059 6.28809L25.5857 0V4.02159L31.9312 8.19959L31.9361 11.402L28.9883 13.3979L26.9773 11.9307V9.44579L23.7001 7.20412V11.7272L18.5052 14.9147L18.4954 23.3352L14.8224 25.4031L12.9367 23.926V21.4882L15.169 19.959V19.9392V16.1658L11.1518 18.8543L8.08113 16.856L5.27597 18.693L9.65949 21.6943V23.5363L6.50031 25.6737L3.27719 23.4916V16.2304L8.06146 12.9907L8.06638 9.87526L14.2028 13.8273L19.3608 10.3941L16.2163 8.55954L14.1586 9.94724L9.6767 6.98317L14.7314 4.03649L19.11 6.60336V2.7754L14.7363 0.211004L4.7941 6.00757L4.78673 11.2232L0 14.4653V25.2566L4.78673 28.4987L4.7941 33.7144L14.4143 40V35.9809L8.06883 31.8029L8.06392 28.6005L11.0117 26.6046L13.0227 28.0717V30.5567L16.2999 32.7984V28.2753L21.5685 25.0878L21.6275 16.6673L25.1776 14.5994L27.0633 16.0765V18.5142L24.831 20.0434V20.0633V23.8366L28.8482 21.1481L31.9189 23.1465L34.724 21.3095L30.3405 18.3057V16.4662L33.4997 14.3288L36.7228 16.5109V23.7721L31.9385 27.0117L31.9336 30.1272L25.7972 26.1727L20.6392 29.5836L23.7837 31.4156L25.8414 30.0552L30.3233 33.0193L25.2686 35.966L20.89 33.3991V37.2246L25.2637 39.789L35.2059 33.9949L35.2133 28.7792L40 25.5371V14.7434L35.2133 11.5013Z" fill="#0084FF" />
    </svg>

  )
}

export default LogoIcon
