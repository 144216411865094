import PageWrapper from "../components/common/PageWrapper";
import VerifyEmailForm from "../components/forms/VerifyEmailForm";

const VerifyEmailPage = () => {
  return (
    <PageWrapper>
      <VerifyEmailForm />
    </PageWrapper>
  );
};

export default VerifyEmailPage;
