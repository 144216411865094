import { ThemeProvider } from "@material-ui/core";
import { Amplify } from "aws-amplify";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/Authcontext";
import "./index.css";
import { store } from "./redux/store";
import { theme } from "./theme";

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Provider store={store}>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={1}
              autoHideDuration={1900}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </Provider>
      </AuthProvider>
    </ThemeProvider>{" "}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
