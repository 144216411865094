/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PreviewLinkResponse } from '../models';
// @ts-ignore
import { UserCreateRequest } from '../models';
// @ts-ignore
import { UserResponse } from '../models';
/**
 * CreateUsersApi - axios parameter creator
 * @export
 */
export const CreateUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreateRequest: UserCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequest' is not null or undefined
            assertParamExists('createUser', 'userCreateRequest', userCreateRequest)
            const localVarPath = `/main/public/createUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} previewLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewLinkData: async (previewLink: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewLink' is not null or undefined
            assertParamExists('getPreviewLinkData', 'previewLink', previewLink)
            const localVarPath = `/main/public/createUser/getPreviewLinkData/{previewLink}`
                .replace(`{${"previewLink"}}`, encodeURIComponent(String(previewLink)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreateUsersApi - functional programming interface
 * @export
 */
export const CreateUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreateUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} previewLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewLinkData(previewLink: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewLinkData(previewLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreateUsersApi - factory interface
 * @export
 */
export const CreateUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreateUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UserCreateRequest} userCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreateRequest: UserCreateRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUser(userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} previewLink 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewLinkData(previewLink: string, options?: any): AxiosPromise<PreviewLinkResponse> {
            return localVarFp.getPreviewLinkData(previewLink, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreateUsersApi - object-oriented interface
 * @export
 * @class CreateUsersApi
 * @extends {BaseAPI}
 */
export class CreateUsersApi extends BaseAPI {
    /**
     * 
     * @param {UserCreateRequest} userCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateUsersApi
     */
    public createUser(userCreateRequest: UserCreateRequest, options?: AxiosRequestConfig) {
        return CreateUsersApiFp(this.configuration).createUser(userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} previewLink 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateUsersApi
     */
    public getPreviewLinkData(previewLink: string, options?: AxiosRequestConfig) {
        return CreateUsersApiFp(this.configuration).getPreviewLinkData(previewLink, options).then((request) => request(this.axios, this.basePath));
    }
}
