import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ContentWrapper from "../components/common/ContentWrapper";
import HeaderWrapper from "../components/common/HeaderWrapper";
import CustomerSearchListAndView, {
  TableView,
} from "../components/history/CustomerSearchListAndView";
import CategoryModal from "../components/modals/CategoryModal";
import CustomPagination from "../components/pagination/CustomPagination";
import BackIconBtn from "../components/svg-icon/BackIconBtn";
import useChatHistory from "../hooks/useChatHistory";
import useCustomer from "../hooks/useCustomer";
import { UserResponse } from "../openapi";
import { ChatHistoryList } from "../openapi/models/chat-history-list";
import {
  clearSearchfromList,
  saveSearchFromList,
  saveSearching,
} from "../redux/features/chatHistory/chatHistorySlice";
import { clearSearchUserEmail } from "../redux/features/customers/cutomersSlice";
import { RootState } from "../redux/store";

const CustomerSearch: React.FC = () => {
  const dispatch = useDispatch();
  const { getCustomersList } = useCustomer();
  const { getChatHistory } = useChatHistory();
  const [page, setPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<
    UserResponse | undefined
  >();
  const { customers } = useSelector((state: RootState) => state.customer);
  const { isLoading, isSearched, pagination, searchFromList } = useSelector(
    (state: RootState) => state.chatHistory
  );

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  let initialValues = {
    email: customerEmail,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.object(),
  });

  const onSubmit = (values: any, actions: any) => {
    if (values.email) {
      setSearchLoading(true);
      dispatch(saveSearchFromList(values.email));
      dispatch(saveSearching(true));
      getChatHistory(page, values.email.id.toString()).then((r: unknown) => {
        const data = r as unknown as ChatHistoryList;
        if (data) {
          setSearchLoading(false);
        }
      });
    }
  };
  const handleBack = () => {
    dispatch(clearSearchUserEmail());
    dispatch(saveSearching(false));
    dispatch(clearSearchfromList(undefined));
    getChatHistory(page);
  };
  useEffect(() => {
    getCustomersList();
    dispatch(clearSearchUserEmail());
    dispatch(saveSearching(false));
    dispatch(clearSearchfromList(undefined));
  }, []);
  useEffect(() => {
    if (!searchFromList) {
      getChatHistory(page);
    } else {
      getChatHistory(page, searchFromList.id.toString());
    }
  }, [page]);

  return (
    <ContentWrapper>
      <Box>
        <HeaderWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Box
                    display={"flex"}
                    justifyContent={
                      isSearched && !isLoading ? "flex-start" : "space-between"
                    }
                    alignItems={"center"}
                  >
                    {isSearched && !isLoading ? (
                      <>
                        <Box
                          mt={1}
                          mr={0.5}
                          component="button"
                          type="reset"
                          onClick={() => {
                            handleBack();
                            formik.resetForm();
                          }}
                          sx={{ cursor: "pointer", border: "none" }}
                        >
                          <BackIconBtn />
                        </Box>
                        <Typography variant="h5">History</Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="h5">History</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            minWidth: "500px",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            limitTags={1}
                            value={formik.values.email}
                            options={
                              customers && customers.length ? customers : []
                            }
                            loading={!customers}
                            getOptionLabel={(option: any) => option.email}
                            forcePopupIcon={false}
                            onChange={(
                              event: React.SyntheticEvent,
                              newValue: any
                            ) => {
                              formik.setFieldValue("email", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="emails"
                                size="small"
                                placeholder={"Select customer"}
                                value={formik.values.email}
                              />
                            )}
                          />
                          <SearchButton
                            size="small"
                            type="submit"
                            variant="contained"
                            disabled={isLoading && searchLoading}
                            startIcon={
                              isLoading && searchLoading ? (
                                <CircularProgress
                                  size="0.6rem"
                                  color="inherit"
                                />
                              ) : null
                            }
                          >
                            Search
                          </SearchButton>
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box pt={3}>
                    <TableView>
                      <CustomerSearchListAndView />
                      <Box pb={3}>
                        {pagination &&
                        pagination.totalPage &&
                        pagination.totalPage > 1 ? (
                          <CustomPagination
                            count={pagination.totalPage}
                            page={
                              pagination.currentPage
                                ? pagination.currentPage
                                : 1
                            }
                            rowCount={pagination.total ?? pagination.total}
                            onPageChange={handlePageChange}
                          />
                        ) : null}
                      </Box>
                    </TableView>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </HeaderWrapper>

        <CategoryModal />
      </Box>
    </ContentWrapper>
  );
};

export default CustomerSearch;

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: "26px",
    marginBottom: "16px",
  },
}));
const CssTextField = styled(TextField)({
  maxWidth: "400px",
  minWidth: "400px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRight: "0",
      borderRadius: "8px 0 0 8px",
    },
  },
});
const SearchButton = styled(Button)({
  textTransform: "capitalize",
  minWidth: "120px",
  paddingLeft: "10px",
  paddingRight: "10px",
});
