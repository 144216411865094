import { Box, Divider, styled, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
// import ConfigurationIcon from "../svg-icon/ConfigurationIcon";
// import ContentIcon from "../svg-icon/ContentIcon";
import FaqIcon from "../svg-icon/FaqIcon";
import HistoryIcon from "../svg-icon/HistoryIcon";
import LogoIcon from "../svg-icon/LogoIcon";
import { DashboardCustomizeOutlined } from "@mui/icons-material";

const LeftNavigation = () => {
	const navigate = useNavigate();
	const navActiveLinkStyle = ({ isActive }: { isActive: boolean }) => {
		return {
			color: isActive ? "#fff" : "#E8ECEF",
			fontWeight: isActive ? "bold" : "normal",
			background: isActive ? "#464F6F80" : "none",
			// borderColor: isActive ? theme.palette.primary.main : "#f8f8f8",
		};
	};

	const handleLogoClick = () => {
		navigate("/contents");
	};
	return (
		<Box bgcolor={"#141718"} flex={1}>
			<LogoWrapper>
				<Logo onClick={handleLogoClick}>
					{/* <Box id="textalyzLogo">Shome-ai</Box>
          <Box id="textalyzLogoSmall">Shome-ai</Box> */}
					<LogoIcon />
					<Typography variant="h4" fontWeight={"700"} color={"#fff"}>
						Shome
					</Typography>
				</Logo>
			</LogoWrapper>
			<NavListWrapper>
				<LinkWrapper to="/dashboard" style={navActiveLinkStyle}>
					<IconWrapper>
						<DashboardCustomizeOutlined fontSize="small" />
					</IconWrapper>
					<Typography variant="body1">Dashboard</Typography>
				</LinkWrapper>
				{/* <LinkWrapper to="/contents" style={navActiveLinkStyle}>
					<IconWrapper>
						<ContentIcon />
					</IconWrapper>
					<Typography variant="body1">Contents</Typography>
				</LinkWrapper> */}
				<LinkWrapper to="/history" style={navActiveLinkStyle}>
					<IconWrapper>
						<HistoryIcon />
					</IconWrapper>
					<Typography variant="body1">History</Typography>
				</LinkWrapper>
				<LinkWrapper to="/faq" style={navActiveLinkStyle}>
					<IconWrapper>
						<FaqIcon />
					</IconWrapper>
					<Typography variant="body1">FAQ</Typography>
				</LinkWrapper>
				{/* <LinkWrapper to="/configuration" style={navActiveLinkStyle}>
					<IconWrapper>
						<ConfigurationIcon />
					</IconWrapper>
					<Typography variant="body1">Configuration</Typography>
				</LinkWrapper> */}
			</NavListWrapper>
			<Divider sx={{ border: "1px solid #232627" }} />
		</Box>
	);
};

export default LeftNavigation;
const LinkWrapper = styled(NavLink)(({ theme }) => ({
	textDecoration: "none",
	fontWeight: "normal",
	padding: "12px 34px",
	width: "100%",
	color: "#3F434A",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	boxSizing: "border-box",
	transition: "0.2s all ease-in-out",
	// borderLeft: "4px solid",
	borderRadius: "8px",
	"&:hover": {
		background: "#464F6F80 !important",
	},
	[theme.breakpoints.down("md")]: {
		p: {
			display: "none",
		},
	},
}));

const LogoWrapper = styled(Box)(() => ({
	minHeight: "80px",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	paddingLeft: "34px",
	paddingRight: "34px",
	background: "#141718",
}));

const NavListWrapper = styled(Box)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "15px",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	marginRight: "10px",
	transition: "0.5s all ease-in-out",
	[theme.breakpoints.down("md")]: {
		marginRight: "0px",
	},
}));

const Logo = styled(Typography)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: "12px",
	cursor: "pointer",
}));
