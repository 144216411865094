import { Box, styled, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { ISignInFormValues } from "../../interfaces/ISignInFormValues";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";

// interface CognitoToken extends JwtPayload {
//   sub: string;
//   "cognito:groups": string[];
// }
const SignInForm = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useAlert();
  const { handleLogin, loading, error, setError } = useAuth();
  // const { loading, error, setError } = useAuth();

  const signInFormValues: ISignInFormValues = {
    email: "",
    password: "",
  };

  const [initialValues, setInitialValues] = useState(signInFormValues);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required().label("Email"),
    password: yup.string().required().label("Password"),
  });

  const onSubmit = (
    values: ISignInFormValues,
    actions: FormikHelpers<ISignInFormValues>
  ) => {
    handleSignIn(values, actions);
  };

  const handleSignIn = async (
    values: ISignInFormValues,
    actions: FormikHelpers<ISignInFormValues>
  ) => {
    try {
      actions.setSubmitting(true);
      setInitialValues(values);
      await handleLogin(values);
    } catch (error: any) {
      showSnackbar(error?.message, error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormWrapper>
        {!loading && error && <FormAlert error={error} setError={setError} />}
        <Typography
          mb={3}
          variant="h1"
          fontSize={"35px"}
          fontWeight={"700"}
          color={"#333F4E"}
        >
          Login
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form style={{ display: "flex", flexDirection: "column" }}>
                <FormField name="email" label="Email" placeholder="Email" />
                <FormField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                />

                <ForgotPassword onClick={handleForgotPassword}>
                  Forgot password?
                </ForgotPassword>

                <FormButton label={"Log in"} loading={loading} />
                <Typography textAlign="center" mt={2}>
                  Don&apos;t have an account?
                  <Typography
                    ml={1}
                    color="primary"
                    display="inline-block"
                    onClick={handleSignUpClick}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Sign Up
                  </Typography>
                </Typography>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Box>
  );
};

export default SignInForm;

const ForgotPassword = styled(Typography)(({ theme }) => ({
  textAlign: "right",
  color: theme.palette.primary.main,
  cursor: "pointer",
  alignSelf: "flex-end",
  "&:hover": {
    textDecoration: "underline",
  },
}));
