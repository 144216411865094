import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useTag from "../../hooks/useTag";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { clearTagDetils } from "../../redux/features/tags/tagsSlice";
import { RootState } from "../../redux/store";
import { TAG_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import ModalTemplate from "./ModalTemplate";

const TagModal = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { createLoading: isLoading, createTag, updateTag } = useTag();
  const { accessToken } = useAuthContext();
  const { modalName } = useSelector((state: RootState) => state.modal);
  const { tag } = useSelector((state: RootState) => state.tag);

  let initialValues = {
    title: "",
  };
  if (tag) {
    initialValues = {
      title: tag.title,
    };
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().min(3).required().label("Keyword"),
  });
  const handleClose = () => {
    dispatch(clearModalName());
    dispatch(clearTagDetils());
  };
  const onSubmit = (values: any, actions: any) => {
    addTag(values, actions);
  };

  const addTag = async (values: any, actions: any) => {
    if (!accessToken) return;
    setIsSubmitting(true);
    if (!tag) {
      createTag(values);
      setIsSubmitting(false);
    } else {
      updateTag(values);
      setIsSubmitting(false);
    }
  };
  return (
    <ModalTemplate
      openModal={modalName === TAG_MODAL}
      title={tag ? "Edit Tag" : "Add Tag"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <FormField
                    name={"title"}
                    label={"Keyword"}
                    placeholder="Enter keyword"
                  />
                  <Stack direction="row" spacing={2} mt={4}>
                    <PrimaryButton
                      text={tag ? "Save" : "Add New"}
                      style={{ width: "100%" }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    />
                    <SecondaryOutlineButton
                      style={{ width: "100%" }}
                      text="Close"
                      onClick={handleClose}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default TagModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));
