import { Typography } from "@mui/material";
import { FC } from "react";

interface IFormHeader {
  title: string;
  subTitle?: string;
}

export const FormHeader: FC<IFormHeader> = ({ title, subTitle }) => {
  return (
    <>
      <Typography
        fontSize={"42px"}
        fontWeight={"700"}
        variant="h4"
        mb={1}
        color={"#fff"}
        textAlign={subTitle ? "left" : "center"}
      >
        {title}
      </Typography>
      <Typography variant="body2" mb={4} color={"#E8ECEF"}>
        {subTitle}
      </Typography>
    </>
  );
};

export default FormHeader;
