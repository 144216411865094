import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { IResetPasswordStep2Form } from "../../interfaces/IResetPasswordStep2Form";
import { getFromLocalStorage } from "../../utils";
import { LS_EMAIL } from "../../utils/constants";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";

const ResetPasswordStep2Form = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useAlert();
  const { loading, error, setError, resetPasswordWithCode } = useAuth();
  const [email, setEmail] = useState<string | null>(null);

  const resetPasswordInitialValue: IResetPasswordStep2Form = {
    email: email || "",
    code: "",
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    let email = getFromLocalStorage(LS_EMAIL);
    if (email) {
      setEmail(email);
      setInitialValues({ ...resetPasswordInitialValue, email: email });
    }
  }, []);

  const [initialValues, setInitialValues] = useState(resetPasswordInitialValue);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required().label("Email"),
    code: yup.string().required().label("Code"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required()
      .label("Password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required()
      .label("Confirm password"),
  });

  const onSubmit = async (
    values: IResetPasswordStep2Form,
    actions: FormikHelpers<IResetPasswordStep2Form>
  ) => {
    try {
      actions.setSubmitting(true);
      setInitialValues(values);
      const response = await resetPasswordWithCode(values);
      if (response) {
        navigate("/login");
      }
    } catch (error: any) {
      showSnackbar(error?.message, error);
      actions.setSubmitting(false);
    }
  };
  const handleSignInClick = () => {
    navigate("/login");
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormWrapper>
        {!loading && error && <FormAlert error={error} setError={setError} />}
        <Typography mb={3} variant="h1" fontSize={'35px'} fontWeight={'700'} color={'#333F4E'}>Reset Password</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                {!email && (
                  <FormField name="email" label="Email" placeholder="Email" />
                )}
                <FormField name="code" label="Code" placeholder="Code" />
                <FormField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <FormField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm password"
                  type="password"
                />
                <FormButton label={"Submit"} loading={loading} />
                <Typography textAlign="center" mt={2}>
                  Back to
                  <Typography
                    mx={1}
                    color="primary"
                    display="inline-block"
                    onClick={handleSignInClick}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Log In
                  </Typography>
                  page
                </Typography>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Box>
  );
};

export default ResetPasswordStep2Form;
