import { Box, styled, Typography } from "@mui/material";
import React from "react";
import ContentWrapper from "../components/common/ContentWrapper";
import HeaderWrapper from "../components/common/HeaderWrapper";
import TagModal from "../components/modals/TagModal";
import TagsList from "../components/tags/TagsList";

const Keywords: React.FC = () => {
  return (
    <ContentWrapper>
      <Box>
        <HeaderWrapper>
          <Heading variant="h5">Configuration</Heading>
        </HeaderWrapper>
        <TagsList />
        <TagModal />
      </Box>
    </ContentWrapper>
  );
};

export default Keywords;

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: "26px",
    marginBottom: "16px",
  },
}));
