import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { FaqRequest } from "../openapi/models/faq-request";
import { FaqUpdateRequest } from "../openapi/models/faq-update-request";
import {
  clearFaqDetils,
  editFaq,
  saveLoading,
  storeFaqs,
  storePaginationDetails,
} from "../redux/features/faq/faqSlice";
import { clearModalName } from "../redux/features/modal/modalSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getFaqService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useFaq = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { page, faq } = useSelector((state: RootState) => state.faq);

  const getFaqList = async (page?: number) => {
    if (accessToken) {
      dispatch(saveLoading(true));
      if (page) {
        let offset = getOffset(page);

        try {
          const service = await getFaqService(accessToken);
          const response = await service.getFaqsForAdmin(PAGE_LIMIT, offset);
          if (response.status === 200 || response.status === 201) {
            dispatch(storeFaqs(response.data.faqs));
            dispatch(storePaginationDetails(response.data));
            dispatch(saveLoading(false));
          }
        } catch (error: any) {
          dispatch(saveLoading(false));
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      } else {
        try {
          const service = await getFaqService(accessToken);
          const response = await service.getFaqsForAdmin("1000", "0");
          if (response.status === 200 || response.status === 201) {
            dispatch(storeFaqs(response.data.faqs));
            dispatch(storePaginationDetails(response.data));
            dispatch(saveLoading(false));
          }
        } catch (error: any) {
          dispatch(saveLoading(false));
          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
          // if (error.response.status === 500) {
          //   navigate("*");
          // }
        }
      }
    }
  };
  const createFaq = async (value?: any) => {
    if (accessToken) {
      // if (value && faq) {
      //   dispatch(saveLoading(true));
      //   const createRequest: FaqRequest = {
      //     chatHistoryId: value.id,
      //     question: value.question,
      //   };
      //   try {
      //     const service = await getFaqService(accessToken);
      //     const response = await service.createFaq(createRequest);
      //     if (response.status === 200 || response.status === 201) {
      //       enqueueSnackbar("Question added successfully", {
      //         variant: "success",
      //       });
      //       dispatch(saveLoading(false));
      //       getFaqList(1);
      //       return response.data;
      //     }
      //   } catch (error: any) {
      //     dispatch(saveLoading(false));

      //     enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
      //       variant: "error",
      //     });
      //   }
      // } else
      if (value) {
        dispatch(saveLoading(true));
        const createRequest: FaqRequest = {
          // chatHistoryId: value.id,
          question: value.question,
          score: parseInt(value.score),
          order: parseInt(value.order),
        };
        try {
          const service = await getFaqService(accessToken);
          const response = await service.createFaq(createRequest);
          if (response.status === 200 || response.status === 201) {
            enqueueSnackbar("Question added successfully", {
              variant: "success",
            });
            dispatch(saveLoading(false));
            getFaqList(1);
            return response.data;
          }
        } catch (error: any) {
          dispatch(saveLoading(false));

          enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
            variant: "error",
          });
        }
      }
    }
  };
  const updateFaq = async (values: any) => {
    if (accessToken && faq) {
      dispatch(saveLoading(true));
      const updateRequest: FaqUpdateRequest = {
        id: faq.id,
        question: values.question,
        score: parseInt(values.score),
        order: parseInt(values.order),
      };
      try {
        const service = await getFaqService(accessToken);
        const response = await service.updateFaq(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Question updated successfully", {
            variant: "success",
          });
          dispatch(saveLoading(false));
          dispatch(editFaq(updateRequest));
          getFaqList(page);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };
  const deleteFaq = async () => {
    if (accessToken && faq) {
      dispatch(saveLoading(true));
      try {
        const productService = await getFaqService(accessToken);
        const response = await productService.deleteFaq(faq.id);
        if (response.status === 200) {
          enqueueSnackbar("Question deleted successfully", {
            variant: "success",
          });
          getFaqList(page);
          dispatch(saveLoading(false));
          dispatch(clearModalName());
          dispatch(clearFaqDetils());
          return response;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        dispatch(saveLoading(false));
      }
    }
  };

  return {
    createFaq,
    getFaqList,
    updateFaq,
    deleteFaq,
  };
};
export default useFaq;
