import AddIcon from "@mui/icons-material/Add";
import { Box, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../components/buttons/PrimaryButton";
import ContentWrapper from "../components/common/ContentWrapper";
import HeaderWrapper from "../components/common/HeaderWrapper";
import ContentsList from "../components/contents/ContentsList";
import ContentModal from "../components/modals/ContentModal";
import useTag from "../hooks/useTag";
import { saveModalName } from "../redux/features/modal/modalSlice";
import { CONTENT_MODAL } from "../utils/constants";

const Content: React.FC = () => {
  const dispatch = useDispatch();
  const { getTagList } = useTag();
  const handleUploadModal = () => {
    dispatch(saveModalName(CONTENT_MODAL));
  };
  useEffect(() => {
    getTagList();
  }, []);
  return (
    <ContentWrapper>
      <Box>
        <HeaderWrapper>
          <Heading variant="h5">Contents</Heading>
          <PrimaryButton
            text="Add Content"
            startIcon={<AddIcon />}
            onClick={handleUploadModal}
          />
        </HeaderWrapper>
        <ContentsList />
        <ContentModal />
      </Box>
    </ContentWrapper>
  );
};

export default Content;

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: "26px",
    marginBottom: "16px",
  },
}));
