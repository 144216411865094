import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useCategory from "../../hooks/useCategory";
import { clearCategoryDetils } from "../../redux/features/categories/categoriesSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { CATEGORY_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import ModalTemplate from "./ModalTemplate";

const CategoryModal = () => {
  const dispatch = useDispatch();
  const {
    createLoading: isLoading,
    createCategory,
    updateCategory,
  } = useCategory();
  const { accessToken } = useAuthContext();
  const { modalName } = useSelector((state: RootState) => state.modal);
  const { category } = useSelector((state: RootState) => state.category);

  let initialValues = {
    title: "",
  };
  if (category) {
    initialValues = {
      title: category.title,
    };
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().min(3).required().label("Category"),
  });
  const handleClose = () => {
    dispatch(clearModalName());
    dispatch(clearCategoryDetils());
  };
  const onSubmit = (values: any, actions: any) => {
    addCategory(values, actions);
  };

  const addCategory = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    if (!accessToken) return;
    if (!category) {
      createCategory(values);
      actions.setSubmitting(false);
    } else {
      updateCategory(values);
      actions.setSubmitting(false);
    }
  };
  return (
    <ModalTemplate
      openModal={modalName === CATEGORY_MODAL}
      title={category ? "Edit Category" : "Add Category"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <FormField
                    name={"title"}
                    label={"Category"}
                    placeholder="Enter category"
                  />
                  <Stack direction="row" spacing={2}>
                    <PrimaryButton
                      text={category ? "Save" : "Add New"}
                      style={{ width: '100%' }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    />
                    <SecondaryOutlineButton
                      style={{ width: '100%' }}
                      text="Close"
                      onClick={handleClose}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default CategoryModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));
