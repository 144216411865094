import { Auth } from "aws-amplify";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { IChangePassword } from "../interfaces/IChangePassword";
import { IResetPasswordStep2Form } from "../interfaces/IResetPasswordStep2Form";
import { ISignInFormValues } from "../interfaces/ISignInFormValues";
import { ISignUpFormValues } from "../interfaces/ISignUpFormValues";
import { IVerifyEmailValues } from "../interfaces/IVerifyEmailValues";
import { saveInLocalStorage } from "../utils";
import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');


export const signUp = async (values: ISignUpFormValues) => {
  const { firstName, lastName, email, password } = values;
  try {
    const { user, userSub, userConfirmed } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    });
    saveInLocalStorage(
      "adminInfo",
      JSON.stringify({
        ...values,
        cognitoUserId: userSub,
        userConfirmed: userConfirmed,
      })
    );

    return user;
  } catch (error) {
    throw error;
  }
};

export const resendConfirmationCode = async (email: string) => {
  try {
    await Auth.resendSignUp(email);
  } catch (error) {
    throw error;
  }
};

export const confirmSignUp = async (values: IVerifyEmailValues) => {
  const { email, code } = values;
  try {
    await Auth.confirmSignUp(email, code);
  } catch (error) {
    throw error;
  }
};

interface CognitoToken extends JwtPayload {
  sub: string;
  "cognito:groups": string[];
}
export const verifyToken = (user: any) => {
  const decode: CognitoToken = jwt_decode(
    user.signInUserSession.accessToken.jwtToken
  );
  const adminUser = decode["cognito:groups"][0];
  if (adminUser === "Admin") {
    return true;
  } else return false;
};

export const signIn = async (values: ISignInFormValues) => {
  const { email, password } = values;
  try {
    const user = await Auth.signIn(email, password);
    const validAdmin = verifyToken(user);
    if (validAdmin) {
      saveInLocalStorage(
        "accessToken",
        user.signInUserSession.accessToken.jwtToken
      );
      saveInLocalStorage("authUser", JSON.stringify(user.attributes));
      window.dispatchEvent(new Event("storage"));
      return user;
    } else {
      return user;
    }
  } catch (error) {
    throw error;
  }
};

export const getResetPasswordCodeByEmail = async (email: string) => {
  try {
    await Auth.forgotPassword(email)
      .then((data) => { })
      .catch((err) => { });
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (values: IResetPasswordStep2Form) => {
  const { email, code, password } = values;
  try {
    const response = Auth.forgotPasswordSubmit(email, code, password);
    return response;
  } catch (error) {
    throw error;
  }
};

export const signout = async () => {
  try {
    console.log('click logout')
    await Auth.signOut();
    logoutChannel.postMessage("Logout")
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (values: IChangePassword) => {
  const { oldPassword, newPassword } = values;
  try {
    const response = Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, newPassword);
    });
    return response;
  } catch (error) {
    throw error;
  }
};
