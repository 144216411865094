import { Box, FormHelperText, styled } from "@mui/material";
import { Field, FormikErrors, getIn, useFormikContext } from "formik";
import * as React from "react";
import { theme } from "../../theme";
import { Label } from "./FormField";

interface IFormField {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
}
export const FormTextArea: React.FC<IFormField> = ({
  name,
  label,
  placeholder = "",
}) => {
  const { errors, touched, values, handleChange, handleBlur } =
    useFormikContext<any>();
  function getStyles(errors: FormikErrors<any>, fieldName: string) {
    if (getIn(errors, fieldName)) {
      return {
        border: "1px solid red",
      };
    }
  }
  return (
    <FormInputWrapper>
      <Label variant={`body2`}>{label}</Label>
      <Field
        className="styled-textarea"
        component="textarea"
        rows="5"
        cols="50"
        height={"150px"}
        name={name}
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched[name] && Boolean(errors[name])}
        placeholder={placeholder}
        aria-label="maximum height"
        style={getStyles(errors, name)}
      />
      {touched[name] && Boolean(errors[name]) && (
        <FormHelperText
          sx={{ fontWeight: "500", color: theme.palette.error.main }}
        >
          {errors[name] as string}
        </FormHelperText>
      )}
    </FormInputWrapper>
  );
};
export const FormInputWrapper = styled(Box)(() => ({
  marginBottom: "28px",
  //minHeight: "90px",
}));
