const QuestionIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40925 4.13925C7.2875 3.3705 8.7125 3.3705 9.59075 4.13925C10.4698 4.908 10.4698 6.1545 9.59075 6.92325C9.4385 7.0575 9.26825 7.16775 9.08825 7.25475C8.5295 7.5255 8.00075 8.004 8.00075 8.625V9.1875M14.75 7.5C14.75 8.38642 14.5754 9.26417 14.2362 10.0831C13.897 10.9021 13.3998 11.6462 12.773 12.273C12.1462 12.8998 11.4021 13.397 10.5831 13.7362C9.76417 14.0754 8.88642 14.25 8 14.25C7.11358 14.25 6.23583 14.0754 5.41689 13.7362C4.59794 13.397 3.85382 12.8998 3.22703 12.273C2.60023 11.6462 2.10303 10.9021 1.76381 10.0831C1.42459 9.26417 1.25 8.38642 1.25 7.5C1.25 5.70979 1.96116 3.9929 3.22703 2.72703C4.4929 1.46116 6.20979 0.75 8 0.75C9.79021 0.75 11.5071 1.46116 12.773 2.72703C14.0388 3.9929 14.75 5.70979 14.75 7.5ZM8 11.4375H8.006V11.4435H8V11.4375Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuestionIcon;
