import { Box, styled } from "@mui/material";
import { FC } from "react";
import FormHeader from "../forms/FormHeader";
import { FormLogo } from "../forms/FormLogo";
import AuthIcon from "../svg-icon/AuthIcon";


interface IPageWrapper {
  children: React.ReactNode;
}
const PageWrapper: FC<IPageWrapper> = ({ children }) => {
  const Wrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    minHeight: "calc(100vh - 100px)",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
  }));
  return <Wrapper>
    <Box sx={{
      width: '600px',
      background: '#141718',
      minHeight: "calc(100vh - 100px)",
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center'
    }}>
      <Box>
        <Box mb={5}>
          <FormLogo />
        </Box>
        <Box>
          <FormHeader
            title="Welcome back!"
            subTitle="Securely sign in to manage your account and access exclusive features"
          />
        </Box>
      </Box>
      <AuthIcon />
      {/* <img src={String(ImageAuth)} alt="img" /> */}
    </Box>
    <Box width={'100%'}>
      {children}
    </Box>
  </Wrapper>;
};

export default PageWrapper;
