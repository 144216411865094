import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { FC } from "react";

interface Props {
  text: string;
}
export const LoadingAnimation: FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <CircularProgress size={"50px"} />
      <LoadingText variant="body1">{text}</LoadingText>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  [theme.breakpoints.up("sm")]: {
    minHeight: "380px",
  },
}));

const LoadingText = styled(Typography)(() => ({
  marginTop: "10px",
}));
