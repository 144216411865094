import { Box, Typography, styled } from "@mui/material";
import Lottie from "lottie-react";
import { FC } from "react";
import emptylist from "./emptylist.json";

interface Props {
  title?: string;
  subTitle?: string;
}
export const EmptyListAnimation: FC<Props> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <Lottie
        className="lottieAnimation"
        autoPlay
        loop={true}
        animationData={emptylist}
      />
      <Title variant="body1">{title}</Title>
      <SubTitle variant="body1">{subTitle}</SubTitle>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  transition: "0.3s all ease-in-out",
  "& .lottieAnimation": {
    width: "200px",
    height: "200px",
  },
  [theme.breakpoints.down("md")]: {
    "& .lottieAnimation": {
      width: "150px",
      height: "150px",
    },
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  textAlign: "center",
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: "6px",
  textAlign: "center",
}));
