import { Box, styled, Typography } from "@mui/material";
import React from "react";
import ContentWrapper from "../components/common/ContentWrapper";
import HeaderWrapper from "../components/common/HeaderWrapper";
import UsersList from "../components/users/UserList";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const Dashboard: React.FC = () => {
	const { customers, pagination } = useSelector(
		(state: RootState) => state.customer
	);
	return (
		<ContentWrapper>
			<Box>
				{/* <HeaderWrapper>
					<Heading variant="h5">Analytics</Heading>
				</HeaderWrapper> */}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">
						Total Users: {pagination?.total || 0}
					</Typography>
					<Typography variant="h6">
						{/* Active Users: {pagination?.activeUsers || 0} */}
						Active Users:
					</Typography>
					<Typography variant="h6">
						Credentialed Users: {pagination?.activeUsers || 0}
					</Typography>
				</Box>
				<UsersList />
			</Box>
		</ContentWrapper>
	);
};

export default Dashboard;

const Heading = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.only("xs")]: {
		fontSize: "26px",
		marginBottom: "16px",
	},
}));
