import React from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  createFilterOptions,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import { IError } from "../../hooks/useAuth";
import useDataset from "../../hooks/useDataset";
import useTag from "../../hooks/useTag";
import { TagResponse } from "../../openapi";
import { clearDatasetDetils } from "../../redux/features/datasets/datasetsSlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { CONTENT_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormField } from "../forms/FormField";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate from "./ModalTemplate";

const ContentModal = () => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthContext();
  const { createTag } = useTag();
  const { createContent, updateDataset } = useDataset();
  const [keywordsList, setKeywordsList] = useState<TagResponse[]>([]);
  const [error, setError] = useState<IError | null>(null);
  const [newKeyword, setNewKeyword] = useState<string>("");
  const { modalName } = useSelector((state: RootState) => state.modal);
  const { tags } = useSelector((state: RootState) => state.tag);
  const { dataset } = useSelector((state: RootState) => state.dataset);
  const filter = createFilterOptions<any>();

  let contentKeywords: TagResponse[] = [];
  let initialValues = {
    title: "Simple title",
    bodyText: "",
    keywords: contentKeywords,
    link: "",
    summary: "Simple summary"
  };
  if (dataset) {
    let temp: any[] = [];
    if (dataset.tagId.length && tags && tags.length) {
      tags.map((item: TagResponse) => {
        for (let i = 0; i <= dataset.tagId.length; i++) {
          if (item.id === Number(dataset.tagId[i])) {
            temp.push(item);
          }
        }
      });
    }

    initialValues = {
      title: dataset.title,
      bodyText: dataset.bodyText,
      keywords: temp,
      link: dataset.link,
      summary: dataset.summary,
    };
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().min(3).required().label("Title"),
    bodyText: Yup.string().min(3).required().label("Content"),
    summary: Yup.string().min(3).required().label("Summery"),
    keywords: Yup.array()
      .of(Yup.object())
      .min(1, "Keywords is a required field")
      .label("Keywords"),
    link: Yup.string().min(3).required().label("Link"),
  });
  const handleClose = () => {
    dispatch(clearModalName());
    dispatch(clearDatasetDetils());
  };
  const onSubmit = (values: any, actions: any) => {
    addDataset(values, actions);
  };

  const addDataset = async (values: any, actions: any) => {
    if (!accessToken) return;
    let tagIds: any[] = [];
    actions.setSubmitting(true);
    if (values.keywords && values.keywords.length) {
      values.keywords.map((item: TagResponse) => {
        tagIds.push(item.id);
      });
    }
    let request = {
      title: values.title,
      bodyText: values.bodyText,
      link: values.link,
      summary: values.summary,
      tagId: tagIds,
    };
    if (dataset) {
      updateDataset(request).then((r: unknown) => {
        const data = r as unknown as any;
        if (data) {
          actions.setSubmitting(false);
          handleClose();
        }
      });
    } else {
      createContent(request).then((r: unknown) => {
        const data = r as unknown as any;
        if (data) {
          actions.setSubmitting(false);
          handleClose();
        }
      });
    }

    // }
  };
  const filteredKeywords = (value: any[]) => {
    return value.filter((item: any) => item.title !== "Create New Keyword");
  };
  let regex = /^[^,;]+$/;
  const createNewKeyword = (formik: any) => {
    let newKeywordList: (TagResponse | TagResponse[] | undefined)[] = [];
    let isPresent: boolean = false;
    if (keywordsList && keywordsList.length) {
      keywordsList.map((item: any) => {
        if (newKeyword.replace(/\s+/g, " ").trim() === item.title) {
          isPresent = true;
        }
      });
    }
    if (formik.values.keywords.length) {
      formik.values.keywords.map((item: TagResponse) => {
        newKeywordList.push(item);
      });
    }

    if (!regex.test(newKeyword)) {
      setError({
        message: "Invalid input.",
        severity: "error",
      });
    } else if (isPresent) {
      setError({
        message: "Keyword already present.",
        severity: "error",
      });
    } else {
      setError(null);
      createTag(newKeyword.replace(/\s+/g, " ").trim()).then((r: unknown) => {
        const data = r as unknown as TagResponse;
        if (data) {
          newKeywordList.push(data);
        }
        formik.setFieldValue("keywords", newKeywordList);
      });
    }
  };
  useEffect(() => {
    if (tags) {
      setKeywordsList(tags);
    }
  }, [tags]);
  return (
    <ModalTemplate
      openModal={modalName === CONTENT_MODAL}
      title={dataset ? "Edit Content" : "Add Content"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Box mb={4}>
                    <Label>Category</Label>
                    <TextField
                      fullWidth
                      variant="filled"
                      name={"category"}
                      label={"Fundraiser"}
                      size="small"
                      disabled
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Box>
                  {dataset &&
                    <Box mb={3}>
                      <FormField
                        name={"title"}
                        label={"Title"}
                        placeholder="Enter title"
                      />
                    </Box>}
                  <Box mb={4}>
                    <Label>Keywords</Label>
                    <Autocomplete
                      multiple
                      size="medium"
                      fullWidth
                      //   limitTags={2}
                      value={formik.values.keywords}
                      filterSelectedOptions
                      id="multiple-limit-tags"
                      options={keywordsList}
                      loading={!keywordsList}
                      disableClearable
                      getOptionLabel={(option: any) => option.title}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      forcePopupIcon={false}
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: any
                      ) => {
                        if (newValue && newValue.length) {
                          newValue.map((item: any) => {
                            if (item.inputValue) {
                              createNewKeyword(formik);
                            } else {
                              formik.setFieldValue(
                                "keywords",
                                filteredKeywords(newValue)
                              );
                            }
                          });
                        } else {
                          formik.setFieldValue(
                            "keywords",
                            filteredKeywords(newValue)
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.every(
                          (option) =>
                            inputValue
                              .toLocaleLowerCase()
                              .replace(/\s+/g, " ")
                              .trim() === option.title.toLowerCase()
                        );
                        let isPresent: boolean = false;
                        if (keywordsList && keywordsList.length) {
                          keywordsList.map((item: any) => {
                            if (
                              inputValue
                                .toLocaleLowerCase()
                                .replace(/\s+/g, " ")
                                .trim() === item.title.toLowerCase()
                            ) {
                              isPresent = true;
                            }
                          });
                        }
                        if (inputValue !== "" && !isExisting && !isPresent) {
                          filtered.unshift({
                            inputValue,
                            title: `Create New Keyword`,
                          });
                        } else if (!filtered.length && inputValue !== "") {
                          filtered.push({
                            inputValue,
                            title: `Create New keyword`,
                          });
                        }
                        return filtered;
                      }}
                      onInputChange={(event: any) => {
                        setError(null);
                        setNewKeyword(event?.target.value);
                      }}
                      renderTags={(value, getTagProps) =>
                        filteredKeywords(value).map((option, index) => {
                          if (!option.inputValue) {
                            return (
                              <Chip
                                variant="filled"
                                label={option.title}
                                size="small"
                                sx={{ borderRadius: "3px", fontSize: "14px" }}
                                {...getTagProps({ index })}
                              />
                            );
                          }
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="keywords"
                          size="medium"
                          placeholder={
                            !formik.values.keywords.length
                              ? "Select or create keywords"
                              : ""
                          }
                          value={formik.values.keywords}
                          error={
                            formik.touched.keywords &&
                            Boolean(formik.errors.keywords)
                          }
                          sx={{
                            "& fieldset": {
                              borderRadius: "8px",
                            },
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name={"keywords"}>
                      {(msg) => <ErrorMessageWapper>{msg}</ErrorMessageWapper>}
                    </ErrorMessage>
                  </Box>
                  <FormTextArea
                    name={"bodyText"}
                    label={"Content"}
                    placeholder="Enter content"
                  />
                  {dataset &&
                    <FormTextArea
                      name={"summary"}
                      label={"Summary"}
                      placeholder="Enter summary"
                    />}
                  <Box mb={3}>
                    <FormField
                      name={"link"}
                      label={"Link"}
                      placeholder="Enter link"
                    />
                  </Box>
                  <Stack direction="row" spacing={2}>
                    <PrimaryButton
                      text={dataset ? "Save" : "Add New"}
                      style={{ width: "100%" }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    />
                    <SecondaryOutlineButton
                      style={{ width: "100%" }}
                      text="Close"
                      onClick={handleClose}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default ContentModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  color: "#2C3659",
  lineHeight: "21px",
  marginBottom: "5px",
}));

const ErrorMessageWapper = styled(Box)(({ theme }) => ({
  color: "#F2323F",
  fontSize: "12px",
  //paddingLeft: "12px",
  fontWeight: 400,
  marginTop: "5px",
}));
