import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponse, UsersResponse } from "../../../openapi";

export interface CustomerState {
  customers: Array<UserResponse> | undefined;
  pagination: UsersResponse | undefined;
  customer: UserResponse | undefined;
  isLoading: boolean;
  searchedUser: string | undefined;
}

const initialState: CustomerState = {
  customers: undefined,
  pagination: undefined,
  customer: undefined,
  searchedUser: undefined,
  isLoading: false,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    storeCustomers: (state, action: PayloadAction<Array<UserResponse>>) => {
      state.customers = action.payload;
    },
    storeCustomerDetails: (state, action: PayloadAction<UserResponse>) => {
      state.customer = action.payload;
    },
    storePaginationDetails: (state, action: PayloadAction<UsersResponse>) => {
      state.pagination = action.payload;
    },
    clearCustomerDetils: (state) => {
      state.customer = undefined;
    },
    storeSearchUserEmail: (state, action: PayloadAction<string>) => {
      state.searchedUser = action.payload;
    },
    clearSearchUserEmail: (state) => {
      state.searchedUser = undefined;
    },

    saveLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  storeCustomers,
  storeCustomerDetails,
  clearCustomerDetils,
  storePaginationDetails,
  storeSearchUserEmail,
  clearSearchUserEmail,
  saveLoading,
} = customersSlice.actions;

export default customersSlice.reducer;
