import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DatasetAdminResponse } from "../../../openapi/models/dataset-admin-response";
import { DatasetListResponse } from "../../../openapi/models/dataset-list-response";
import { DatasetUpdateReq } from "../../../openapi/models/dataset-update-req";

export interface DatasetState {
  datasets: Array<DatasetAdminResponse> | undefined;
  pagination: DatasetListResponse | undefined;
  dataset: DatasetAdminResponse | undefined;
  isLoading: boolean;
}

const initialState: DatasetState = {
  dataset: undefined,
  pagination: undefined,
  datasets: undefined,
  isLoading: false,
};

export const datasetsSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    storeDatasets: (
      state,
      action: PayloadAction<Array<DatasetAdminResponse>>
    ) => {
      state.datasets = action.payload;
    },
    editDataset: (
      state,
      action: PayloadAction<DatasetUpdateReq | DatasetAdminResponse>
    ) => {
      state.datasets =
        state.datasets &&
        state.datasets?.map((dataset: DatasetAdminResponse) =>
          dataset.id === action.payload.id
            ? { ...dataset, ...action.payload }
            : dataset
        );
    },
    storeDatasetDetails: (
      state,
      action: PayloadAction<DatasetAdminResponse>
    ) => {
      state.dataset = action.payload;
    },
    storePaginationDetails: (
      state,
      action: PayloadAction<DatasetListResponse>
    ) => {
      state.pagination = action.payload;
    },
    clearDatasetDetils: (state) => {
      state.dataset = undefined;
    },
    saveLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  storePaginationDetails,
  storeDatasetDetails,
  storeDatasets,
  clearDatasetDetils,
  editDataset,
  saveLoading,
} = datasetsSlice.actions;

export default datasetsSlice.reducer;
