import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, Chip, useMediaQuery } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
import useDataset from "../../hooks/useDataset";
import { TagResponse } from "../../openapi";
import { storeDatasetDetails } from "../../redux/features/datasets/datasetsSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { CONTENT_MODAL, DELETE_CONTENT_MODAL } from "../../utils/constants";
import DeleteContentModal from "../modals/DeleteContentModal";
import { TableTemplate } from "../table/TableTemplate";
import { TeamDataGrid } from "./ContentDataGrid";

const ContentsList = () => {
  const { getDatasetList, getDatasetById } = useDataset();
  const dispatch = useDispatch();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  //const [searchValue, setSearchValue] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const { datasets, pagination, isLoading } = useSelector(
    (state: RootState) => state.dataset
  );
  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleEditClick = (params: GridRowParams) => {
    getDatasetById(params.row.id).then((r: any) => {
      dispatch(saveModalName(CONTENT_MODAL));
    });
  };
  const handleDelete = (params: GridRowParams) => {
    dispatch(storeDatasetDetails(params.row));
    dispatch(saveModalName(DELETE_CONTENT_MODAL));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      flex: isSm ? 0.2 : undefined,
      width: isXs ? 70 : undefined,
    },
    {
      field: "bodyText",
      headerName: "Content",
      sortable: false,
      flex: isSm ? 0.7 : undefined,
      width: isXs ? 180 : undefined,
    },
    {
      field: "link",
      headerName: "Link",
      sortable: false,
      flex: isSm ? 0.7 : undefined,
      width: isXs ? 180 : undefined,
    },
    {
      field: "keywords",
      headerName: "Keywords",
      sortable: false,
      flex: isSm ? 1 : undefined,
      width: isXs ? 220 : undefined,
      renderCell: (params: GridRenderCellParams) => {
        let keywordLeft = null;
        if (params.row.keywords.length > 2) {
          keywordLeft = params.row.keywords.length - 2;
        }
        return (
          <Box maxWidth={"150px"}>
            {params.row.keywords.length > 2 ? (
              <>
                <Chip
                  variant="filled"
                  label={params.row.keywords[0].title}
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                />
                <Chip
                  variant="filled"
                  label={params.row.keywords[1].title}
                  size="small"
                  sx={{
                    borderRadius: "4px",
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                />
                + {keywordLeft}
              </>
            ) : params.row.keywords.length <= 2 ||
              params.row.keywords.length !== 0 ? (
              params.row.keywords.map((data: TagResponse, index: number) => {
                return (
                  <Chip
                    key={index}
                    variant="filled"
                    label={data.title}
                    size="small"
                    sx={{
                      borderRadius: "4px",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  />
                );
              })
            ) : null}
          </Box>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CreateOutlined fontSize="small" />}
          label="Edit"
          onClick={() => handleEditClick(params)}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlineOutlined fontSize="small" />}
          label="Delete"
          onClick={() => handleDelete(params)}
        />,
      ],
    },
  ];
  // let searchedListers: any[] = [];
  // const handleSearch = async (value: string) => {
  //   setSearchValue(value);
  // };
  useEffect(() => {
    getDatasetList(page);
  }, [page]);
  return (
    <Box pt={3}>
      <TableTemplate
        // search={
        //   <SearchInput
        //     searchCallback={handleSearch}
        //     searchLabel={`Search Team Members...`}
        //   />
        // }
        list={datasets}
        dataGrid={
          <TeamDataGrid
            paginationData={pagination}
            list={datasets}
            columns={columns}
            page={page}
            handlePageChange={handlePageChange}
          />
        }
        buttonGroups={null}
        emptyView={<EmptyListAnimation title="No content found" />}
        loading={isLoading}
      />
      <DeleteContentModal />
    </Box>
  );
};
export default ContentsList;
