import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { BULK_TAG_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import BulkTagModal from "../modals/BulkTagModal";
const TagHeaderSection = () => {
  const dispatch = useDispatch();

  const handleBulkTag = () => {
    dispatch(saveModalName(BULK_TAG_MODAL));
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" ml={2}>
          Keywords
        </Typography>
        <PrimaryButton
          text="Add Keywords"
          startIcon={<AddIcon />}
          onClick={handleBulkTag}
        />
      </Box>
      <BulkTagModal />
    </Box>
  );
};
export default TagHeaderSection;
