import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
  palette: {
    primary: {
      main: "#536FE1",
      dark: "#3455DC",
      light: "#647DE4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#141518",
      dark: "#050506",
      //light: "#2C2D30",
      light: "#606266",
      contrastText: "#fff",
    },
    error: {
      main: "#F2323F",
      light: "#FA3F48",
      dark: "#D92935",
      contrastText: "#fff",
    },
    success: {
      main: "#3DBA4E",
      light: "#47C257",
      dark: "#299E39",
      contrastText: "#fff",
    },
    warning: {
      main: "#FCB017",
      light: "#FFBC21",
      dark: "#CF9216",
      contrastText: "#fff",
    },
    info: {
      main: "#23BA99",
      light: "#2EC39F",
      dark: "#16987C",
      contrastText: "#fff",
    },
    text: {
      primary: "#141518",
      //secondary: "#fff",
    },
    background: {
      default: "#fff",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "Rubik, Poppins, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    h1: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "42px",
      fontWeight: 500,
      lineHeight: "48px",
    },
    h2: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "35px",
      fontWeight: 500,
      lineHeight: "40px",
    },
    h3: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "31px",
      fontWeight: 500,
      lineHeight: "36px",
    },
    h4: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "32px",
    },
    h5: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "28px",
    },
    h6: {
      fontFamily: "Rubik,sans-serif",
      fontSize: "21px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    subtitle1: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "21px",
      fontWeight: 400,
      lineHeight: "32px",
    },
    subtitle2: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "17px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    body1: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    body2: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    caption: {
      fontFamily: "Poppins, sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "10px",
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "& .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall": {
            height: "27px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontWeight: "500",
          fontSize: "15px",
          textTransform: "capitalize",
          boxShadow: "none",
          minHeight: "40px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          //height: "43px",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          "& .action-class": {
            justifyContent: "center !important",
          },
          "& .custom-header": {
            backgroundColor: "#F8FAFD !important",
          },
          "& .super-app-theme--true": {
            backgroundColor: "#FFEEED",

            "&:hover": {
              backgroundColor: "#FFDFDD",
            },
            "&.Mui-selected": {
              backgroundColor: "#FFEEED",
              "&:hover": {
                backgroundColor: "#FFDFDD",
              },
            },
          },
        },
        iconSeparator: {
          display: "none",
        },
        columnHeaderTitle: {
          color: "#8A9099 !important",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#606266",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: "#606266",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#606266",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#606266",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          marginLeft: "0px",
          fontSize: "0.75rem",
          color: "#F2323F",
        },
      },
    },
  },
});
