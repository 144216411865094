import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import {
  saveLoading,
  storeChatHistories,
  storePaginationDetails,
  storeThreadedHistories,
  storeThreadPagination,
} from "../redux/features/chatHistory/chatHistorySlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getChatHistoryService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useChatHistory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { category } = useSelector((state: RootState) => state.category);

  const getChatHistory = async (page: number, userId?: string) => {
    const offset = getOffset(page);
    if (accessToken) {
      //  setIsLoading(true);
      dispatch(saveLoading(true));
      try {
        const service = await getChatHistoryService(accessToken);
        const response = await service.getHistoryByAdmin(
          PAGE_LIMIT,
          offset,
          userId
        );
        if (response.status === 200 || response.status === 201) {
          dispatch(saveLoading(false));
          dispatch(storeChatHistories(response.data.history));
          dispatch(storePaginationDetails(response.data));
          // dispatch(clearSearchUserEmail());
          return response.data;
        }
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const getThreadedHistory = async (
    page: number,
    userId: string,
    threadId: string
  ) => {
    const offset = getOffset(page);
    if (accessToken) {
      dispatch(saveLoading(true));
      try {
        const service = await getChatHistoryService(accessToken);
        const response = await service.getThreadedHistoryByAdmin(
          threadId,
          userId,
          PAGE_LIMIT,
          offset
        );
        if (response.status === 200 || response.status === 201) {
          dispatch(saveLoading(false));
          dispatch(storeThreadedHistories(response.data.history));
          dispatch(storeThreadPagination(response.data));
          return response.data;
        }
      } catch (error: any) {
        dispatch(saveLoading(false));
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  // const createCategory = async (value: CategoryRequest) => {
  //   if (accessToken && value) {
  //     setCreateLoading(true);
  //     const createRequest: CategoryRequest = {
  //       title: value.title,
  //     };
  //     try {
  //       const service = await getCategoryService(accessToken);
  //       const response = await service.createCategory(createRequest);
  //       if (response.status === 200 || response.status === 201) {
  //         enqueueSnackbar("Category created successfully", {
  //           variant: "success",
  //         });
  //         setCreateLoading(false);
  //         //dispatch(createTags(true));
  //         dispatch(clearModalName());
  //         getCategoryList(1);
  //         return response.data;
  //       }
  //     } catch (error: any) {
  //       enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
  //         variant: "error",
  //       });
  //       setCreateLoading(false);
  //     }
  //   }
  // };
  // const updateCategory = async (values: any) => {
  //   if (accessToken && category) {
  //     setIsLoading(true);
  //     const updateRequest: CategoryUpdateRequest = {
  //       id: category.id,
  //       title: values.title,
  //     };
  //     try {
  //       const service = await getCategoryService(accessToken);
  //       const response = await service.updateCategory(updateRequest);
  //       if (response.status === 200 || response.status === 201) {
  //         enqueueSnackbar("Category updated successfully", {
  //           variant: "success",
  //         });
  //         setIsLoading(false);
  //         dispatch(clearCategoryDetils());
  //         dispatch(editCategory(updateRequest));
  //         dispatch(clearModalName());
  //         return response.data;
  //       }
  //     } catch (error: any) {
  //       enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
  //         variant: "error",
  //       });
  //       setIsLoading(false);
  //     }
  //   }
  // };
  // const deleteCategory = async (id: number) => {
  //   if (accessToken) {
  //     setIsLoading(true);
  //     try {
  //       const productService = await getCategoryService(accessToken);
  //       const response = await productService.deleteCategory(id);
  //       if (response.status === 200) {
  //         enqueueSnackbar("Category deleted successfully", {
  //           variant: "success",
  //         });
  //         getCategoryList(1);
  //         setIsLoading(false);
  //         dispatch(clearCategoryDetils());
  //         dispatch(clearModalName());
  //       }
  //     } catch (error: any) {
  //       enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
  //         variant: "error",
  //       });
  //       setIsLoading(false);
  //     }
  //   }
  // };

  return {
    getChatHistory,
    isLoading,
    getThreadedHistory,
  };
};

export default useChatHistory;
