import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CategoriesResponse,
  CategoryResponse,
  CategoryUpdateRequest,
} from "../../../openapi";

export interface CategoryState {
  categories: Array<CategoryResponse> | undefined;
  pagination: CategoriesResponse | undefined;
  category: CategoryResponse | undefined;
}

const initialState: CategoryState = {
  categories: undefined,
  pagination: undefined,
  category: undefined,
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    storeCategories: (
      state,
      action: PayloadAction<Array<CategoryResponse>>
    ) => {
      state.categories = action.payload;
    },
    editCategory: (
      state,
      action: PayloadAction<CategoryUpdateRequest | CategoryResponse>
    ) => {
      state.categories =
        state.categories &&
        state.categories?.map((category: CategoryResponse) =>
          category.id === action.payload.id
            ? { ...category, ...action.payload }
            : category
        );
    },
    storeCategoryDetails: (state, action: PayloadAction<CategoryResponse>) => {
      state.category = action.payload;
    },
    storePaginationDetails: (
      state,
      action: PayloadAction<CategoriesResponse>
    ) => {
      state.pagination = action.payload;
    },
    clearCategoryDetils: (state) => {
      state.category = undefined;
    },
  },
});

export const {
  storeCategories,
  storePaginationDetails,
  storeCategoryDetails,
  editCategory,
  clearCategoryDetils,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
