import { Box, Typography } from "@mui/material";
import MainLogo from "../svg-icon/MainLogo";


export const FormLogo = () => {
  return (
    <Box mb={1} display={'flex'} alignItems={'center'} gap={'8px'}>
      <MainLogo />
      <Typography variant="h2" fontSize={'35px'} fontWeight={'700'} color='#fff'>Shome</Typography>
    </Box>
  );
};
