import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TagResponse, TagsResponse, TagUpdateRequest } from "../../../openapi";

export interface TagState {
  tags: Array<TagResponse> | undefined;
  pagination: TagsResponse | undefined;
  tag: TagResponse | undefined;
  deleted: boolean;
}

const initialState: TagState = {
  tags: undefined,
  pagination: undefined,
  tag: undefined,
  deleted: false,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    storeTags: (state, action: PayloadAction<Array<TagResponse>>) => {
      state.tags = action.payload;
    },
    editTag: (state, action: PayloadAction<TagUpdateRequest | TagResponse>) => {
      state.tags =
        state.tags &&
        state.tags?.map((tag: TagResponse) =>
          tag.id === action.payload.id ? { ...tag, ...action.payload } : tag
        );
    },
    storeTagDetails: (state, action: PayloadAction<TagResponse>) => {
      state.tag = action.payload;
    },
    storePaginationDetails: (state, action: PayloadAction<TagsResponse>) => {
      state.pagination = action.payload;
    },
    clearTagDetils: (state) => {
      state.tag = undefined;
    },
    storeDeleteStatus: (state, action: PayloadAction<boolean>) => {
      state.deleted = action.payload;
    },
  },
});

export const {
  storeTags,
  storePaginationDetails,
  storeTagDetails,
  editTag,
  clearTagDetils,
  storeDeleteStatus,
} = tagsSlice.actions;

export default tagsSlice.reducer;
