import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../contexts/Authcontext";
import { CategoryRequest, CategoryUpdateRequest } from "../openapi";
import {
  clearCategoryDetils,
  editCategory,
  storeCategories,
  storePaginationDetails,
} from "../redux/features/categories/categoriesSlice";
import { clearModalName } from "../redux/features/modal/modalSlice";
import { RootState } from "../redux/store";
import { getOffset } from "../utils";
import { getCategoryService } from "../utils/apiHelpers";
import { PAGE_LIMIT } from "../utils/constants";

const useCategory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useAuthContext();
  const { category } = useSelector((state: RootState) => state.category);

  const getCategoryList = async (page: number) => {
    const offset = getOffset(page);
    if (accessToken) {
      setIsLoading(true);
      try {
        const service = await getCategoryService(accessToken);
        const response = await service.getCategories(PAGE_LIMIT, offset);
        if (response.status === 200 || response.status === 201) {
          dispatch(storeCategories(response.data.categories));
          dispatch(storePaginationDetails(response.data));
          setIsLoading(false);
        }
      } catch (error: any) {
        setIsLoading(false);
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        // if (error.response.status === 500) {
        //   navigate("*");
        // }
      }
    }
  };
  const createCategory = async (value: CategoryRequest) => {
    if (accessToken && value) {
      setCreateLoading(true);
      const createRequest: CategoryRequest = {
        title: value.title,
      };
      try {
        const service = await getCategoryService(accessToken);
        const response = await service.createCategory(createRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Category created successfully", {
            variant: "success",
          });
          setCreateLoading(false);
          //dispatch(createTags(true));
          dispatch(clearModalName());
          getCategoryList(1);
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setCreateLoading(false);
      }
    }
  };
  const updateCategory = async (values: any) => {
    if (accessToken && category) {
      setIsLoading(true);
      const updateRequest: CategoryUpdateRequest = {
        id: category.id,
        title: values.title,
      };
      try {
        const service = await getCategoryService(accessToken);
        const response = await service.updateCategory(updateRequest);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Category updated successfully", {
            variant: "success",
          });
          setIsLoading(false);
          dispatch(clearCategoryDetils());
          dispatch(editCategory(updateRequest));
          dispatch(clearModalName());
          return response.data;
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };
  const deleteCategory = async (id: number) => {
    if (accessToken) {
      setIsLoading(true);
      try {
        const productService = await getCategoryService(accessToken);
        const response = await productService.deleteCategory(id);
        if (response.status === 200) {
          enqueueSnackbar("Category deleted successfully", {
            variant: "success",
          });
          getCategoryList(1);
          setIsLoading(false);
          dispatch(clearCategoryDetils());
          dispatch(clearModalName());
        }
      } catch (error: any) {
        enqueueSnackbar(error?.response?.data?.message ?? error.toString(), {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  return {
    createCategory,
    getCategoryList,
    updateCategory,
    deleteCategory,
    isLoading,
    createLoading,
  };
};

export default useCategory;
