import { Box, Link, Stack, styled, Typography } from "@mui/material";
import { Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { clearHistoryDetils } from "../../redux/features/chatHistory/chatHistorySlice";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import { READ_MORE_DATASET } from "../../utils/constants";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import ModalTemplate from "./ModalTemplate";

const ReadMoreModal = () => {
  const dispatch = useDispatch();

  const { modalName } = useSelector((state: RootState) => state.modal);
  const { historyDetails } = useSelector(
    (state: RootState) => state.chatHistory
  );

  const handleClose = () => {
    dispatch(clearModalName());
    dispatch(clearHistoryDetils());
  };
  let refLink: any[] = [];
  let keywordsLink: any[] = [];
  if (
    historyDetails &&
    historyDetails.searchResponse &&
    historyDetails.searchResponse.result &&
    historyDetails.searchResponse.result.length > 0 &&
    historyDetails.searchResponse.result[0].link
  ) {
    historyDetails.searchResponse.result.map((item: any) => {
      refLink.push(item.link);
      keywordsLink.push(item.keywords);
    });
  }
  return (
    <ModalTemplate
      openModal={modalName === READ_MORE_DATASET}
      title={"History Details"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          {historyDetails && (
            <Form style={{ width: "100%" }}>
              <Box mb={2}>
                <Label>Question</Label>
                <Typography variant="subtitle1">
                  {historyDetails.question}
                </Typography>
              </Box>
              <Box mb={2.5}>
                <Label>Short Description</Label>
                <Box
                  boxShadow={2}
                  sx={{
                    maxHeight: "200px",
                    overflow: "scroll",
                    scrollBehavior: "auto",
                    //boxSizing: "border-box",
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    borderRadius: "4px",
                    padding: "5px",
                    marginTop: "7px",
                  }}
                >
                  <Typography variant="subtitle1">
                    {historyDetails.searchResponse.shortDescription}
                  </Typography>
                </Box>
              </Box>
              <Box mb={2}>
                <Label>Provided Link</Label>
                {refLink &&
                  refLink.length > 0 &&
                  refLink.map((item: any) => {
                    return (
                      <Link href={item} underline="none" target="_blank">
                        <Typography
                          variant="subtitle1"
                          color={theme.palette.primary.main}
                          sx={{
                            textWrap: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item}
                        </Typography>
                      </Link>
                    );
                  })}
              </Box>
              <Box mb={2}>
                <Label>Keywords</Label>
                <Typography variant="subtitle1">
                  {keywordsLink.join(" ")}
                </Typography>
              </Box>
              <Box mb={2.5}>
                <Label>User Email</Label>
                <Typography variant="subtitle1">
                  {historyDetails.email}
                </Typography>
              </Box>
              <Stack direction="row" spacing={2}>
                {/* <PrimaryButton
                      text={"Add New"}
                      style={{ width: '100%' }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    /> */}
                <SecondaryOutlineButton
                  style={{ width: "100%" }}
                  text="Close"
                  onClick={handleClose}
                />
              </Stack>
            </Form>
          )}
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default ReadMoreModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: "#2C3659",
  fontSize: "16px",
  fontWeight: "600",
}));
