import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { IVerifyEmailValues } from "../../interfaces/IVerifyEmailValues";
import { getFromLocalStorage } from "../../utils";
import { LS_EMAIL } from "../../utils/constants";
import FormWrapper from "../common/FormWrapper";
import Timer from "../timer";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";

const VerifyEmailForm = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useAlert();
  const {
    loading,
    error,
    handleConfirmEmail,
    handleResendCode,
    resendCountdownStart,
    setResendCountdownStart,
  } = useAuth();
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    setEmail(getFromLocalStorage(LS_EMAIL));
  }, []);

  const verifyEmailValues: IVerifyEmailValues = {
    email: email || "",
    code: "",
  };

  useEffect(() => {
    let email = getFromLocalStorage(LS_EMAIL);
    if (email) {
      setEmail(email);
      setInitialValues({ ...verifyEmailValues, email: email });
    }
  }, []);

  const [initialValues, setInitialValues] = useState(verifyEmailValues);

  const validationSchema = yup.object().shape({
    code: yup.string().required().label("Code"),
    email: yup.string().email("Invalid email").required().label("Email"),
  });

  const onSubmit = async (
    values: IVerifyEmailValues,
    actions: FormikHelpers<IVerifyEmailValues>
  ) => {
    try {
      actions.setSubmitting(true);
      setInitialValues(values);
      setResendCountdownStart(false);
      const response = await handleConfirmEmail(values);
      if (response) {
        navigate("/login");
      }
    } catch (error: any) {
      showSnackbar(error?.message, { variant: "error" });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormWrapper>
        {!loading && error && <FormAlert error={error} />}
        <Typography mb={3} variant="h1" fontSize={'35px'} fontWeight={'700'} color={'#333F4E'}>Verify email</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => {
            return (
              <Form>
                {!email && (
                  <FormField name="email" label="Email" placeholder="Email" />
                )}
                <FormField name="code" label="Code" placeholder="Code" />
                <Typography textAlign="right" my={2}>
                  Didn&apos;t receive the code?
                  {resendCountdownStart ? (
                    <Timer setResendCountdownStart={setResendCountdownStart} />
                  ) : (
                    <Typography
                      display="inline-block"
                      ml={1}
                      color="primary"
                      sx={{
                        cursor: "pointer",
                        "&:hover": { textDecoration: "underline" },
                      }}
                      onClick={handleResendCode}
                    >
                      Resend
                    </Typography>
                  )}
                </Typography>

                <FormButton label={"Verify"} loading={loading} />
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Box>
  );
};

export default VerifyEmailForm;
