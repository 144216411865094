import { useEffect, useState } from "react";
import PageWrapper from "../components/common/PageWrapper";
import ResetPasswordStep1Form from "../components/forms/ResetPasswordStep1Form";
import ResetPasswordStep2Form from "../components/forms/ResetPasswordStep2Form";

const VerifyEmailPage = () => {
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const checkEmail = () => {
      const email = localStorage.getItem("email");
      if (email) {
        setEmail(email);
      }
    };

    window.addEventListener("storage", checkEmail);
    return () => {
      window.removeEventListener("storage", checkEmail);
    };
  }, []);

  return (
    <PageWrapper>
      {email ? <ResetPasswordStep2Form /> : <ResetPasswordStep1Form />}
    </PageWrapper>
  );
};

export default VerifyEmailPage;
