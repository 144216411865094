/* tslint:disable */
/* eslint-disable */
/**
 * Main Service API
 * Main Service API
 *
 * The version of the OpenAPI document: 2.11.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FaqList } from '../models';
// @ts-ignore
import { FaqRequest } from '../models';
// @ts-ignore
import { FaqResponse } from '../models';
// @ts-ignore
import { FaqUpdateRequest } from '../models';
/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {FaqRequest} faqRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq: async (faqRequest: FaqRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqRequest' is not null or undefined
            assertParamExists('createFaq', 'faqRequest', faqRequest)
            const localVarPath = `/main/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faqRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFaq', 'id', id)
            const localVarPath = `/main/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsForAdmin: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/faq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsForCustomer: async (limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/faq/getFaqs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaq: async (faqUpdateRequest: FaqUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'faqUpdateRequest' is not null or undefined
            assertParamExists('updateFaq', 'faqUpdateRequest', faqUpdateRequest)
            const localVarPath = `/main/faq/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(faqUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {FaqRequest} faqRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFaq(faqRequest: FaqRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFaq(faqRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFaq(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFaq(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaqsForAdmin(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaqsForAdmin(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFaqsForCustomer(limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFaqsForCustomer(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFaq(faqUpdateRequest: FaqUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFaq(faqUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {FaqRequest} faqRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFaq(faqRequest: FaqRequest, options?: any): AxiosPromise<FaqResponse> {
            return localVarFp.createFaq(faqRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFaq(id: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteFaq(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsForAdmin(limit?: string, offset?: string, options?: any): AxiosPromise<FaqList> {
            return localVarFp.getFaqsForAdmin(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFaqsForCustomer(limit?: string, offset?: string, options?: any): AxiosPromise<FaqList> {
            return localVarFp.getFaqsForCustomer(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {FaqUpdateRequest} faqUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFaq(faqUpdateRequest: FaqUpdateRequest, options?: any): AxiosPromise<FaqResponse> {
            return localVarFp.updateFaq(faqUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {FaqRequest} faqRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public createFaq(faqRequest: FaqRequest, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).createFaq(faqRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public deleteFaq(id: number, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).deleteFaq(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public getFaqsForAdmin(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).getFaqsForAdmin(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public getFaqsForCustomer(limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).getFaqsForCustomer(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {FaqUpdateRequest} faqUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public updateFaq(faqUpdateRequest: FaqUpdateRequest, options?: AxiosRequestConfig) {
        return FaqApiFp(this.configuration).updateFaq(faqUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
