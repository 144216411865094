import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useAuthContext } from "../../contexts/Authcontext";
import useTag from "../../hooks/useTag";
import { clearModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { BULK_TAG_MODAL } from "../../utils/constants";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryOutlineButton from "../buttons/SecondaryOutLineButton";
import { FormTextArea } from "../forms/FormTextArea";
import ModalTemplate from "./ModalTemplate";

const BulkTagModal = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { createLoading: isLoading, createTag, createBulkTags } = useTag();
  const { accessToken } = useAuthContext();
  const { modalName } = useSelector((state: RootState) => state.modal);

  let initialValues = {
    keywords: "",
  };
  const validationSchema = Yup.object().shape({
    keywords: Yup.string().min(3).required().label("Keywords"),
  });
  const handleClose = () => {
    dispatch(clearModalName());
  };
  const onSubmit = (values: any, actions: any) => {
    addBulkTag(values, actions);
  };

  const addBulkTag = async (values: any, actions: any) => {
    if (!accessToken) return;
    setIsSubmitting(true);
    const response = await createBulkTags(values);
    if (response) {
      handleClose();
      setIsSubmitting(false);
    }
  };
  return (
    <ModalTemplate
      openModal={modalName === BULK_TAG_MODAL}
      title={"Add Keywords"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form style={{ width: "100%" }}>
                  <FormTextArea
                    name={"keywords"}
                    label={"Keywords"}
                    placeholder="Enter keywords"
                  />
                  <Stack direction="row" spacing={2} mt={4}>
                    <PrimaryButton
                      text={"Add New"}
                      style={{ width: "100%" }}
                      disabled={formik.isSubmitting}
                      type="submit"
                      icon={
                        formik.isSubmitting ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : null
                      }
                    />
                    <SecondaryOutlineButton
                      style={{ width: "100%" }}
                      text="Close"
                      onClick={handleClose}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </Box>
    </ModalTemplate>
  );
};
export default BulkTagModal;

export const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "5px",
  boxSizing: "border-box",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));
