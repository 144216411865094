import PageWrapper from "../components/common/PageWrapper";
import SignUpForm from "../components/forms/SignUpForm";

const SignUpPage = () => {
  return (
    <PageWrapper>
      <SignUpForm />
    </PageWrapper>
  );
};

export default SignUpPage;
