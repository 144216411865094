import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useAuth from "../../hooks/useAuth";
import { ISignUpFormValues } from "../../interfaces/ISignUpFormValues";
import FormWrapper from "../common/FormWrapper";
import { FormAlert } from "./FormAlert";
import { FormButton } from "./FormButton";
import { FormField } from "./FormField";

const SignUpForm = () => {
  const navigate = useNavigate();
  const { loading, error, setError, handleCreateUser } = useAuth();

  const initialValues: ISignUpFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().min(2).required().label("First name"),
    lastName: yup.string().min(2).required().label("Last name"),
    email: yup.string().email("Invalid email").required("Required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .required()
      .label("Password"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required()
      .label("Confirm password"),
  });

  const onSubmit = (
    values: ISignUpFormValues,
    actions: FormikHelpers<ISignUpFormValues>
  ) => {
    handleCreateAdmin(values, actions);
  };

  const handleCreateAdmin = async (
    values: ISignUpFormValues,
    actions: FormikHelpers<ISignUpFormValues>
  ) => {
    try {
      const response = await handleCreateUser(values);
      if (response) {
        actions.setSubmitting(false);
      }
    } catch (error: any) {
      actions.setSubmitting(false);
      setError({ message: error?.message, severity: "error" });
    }
  };

  const handleSignInClick = () => {
    navigate("/login");
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <FormWrapper>
        {!loading && error && <FormAlert error={error} setError={setError} />}

        <Typography
          mb={3}
          variant="h1"
          fontSize={"48px"}
          fontWeight={"700"}
          color={"#333F4E"}
        >
          Sign Up
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormField
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                />
                <FormField
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                />
                <FormField name="email" label="Email" placeholder="Email" />

                <FormField
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <FormField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                />

                <FormButton label={"Submit"} loading={loading} />

                <Typography textAlign="center" mt={2}>
                  Already have an account?
                  <Typography
                    ml={1}
                    color="primary"
                    display="inline-block"
                    onClick={handleSignInClick}
                    sx={{ cursor: "pointer" }}
                  >
                    Log In
                  </Typography>
                </Typography>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Box>
  );
};

export default SignUpForm;
