import { Email, Lock, Password, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  styled,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { FC, useState } from "react";

interface IFormField {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  minRows?: number;
}

export const FormField: FC<IFormField> = ({
  name,
  label,
  placeholder = "",
  type = "text",
  disabled,
  multiline,
  minRows,
}) => {
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur: formikHandleBlur,
  } = useFormikContext<any>();
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormInputWrapper>
      <Label>{label}</Label>
      <Field
        fullWidth
        name={name}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        value={values[name]}
        size="medium"
        onChange={handleChange}
        onBlur={(e: any) => {
          formikHandleBlur(e);
        }}
        error={touched[name] && Boolean(errors[name])}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        minRows={minRows}
        inputRef={inputRef}
        endAdornment={values[name].length > 0 &&
          type === "password" ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null
        }
        startAdornment={<InputAdornment position="start">
          {name === 'firstName' || name === 'lastName' ? <Person />
            : name === 'email' ? <Email /> :
              name === 'password' || name === 'confirmPassword' ? <Lock />
                : name === 'code' ? <Password />
                  : null}

        </InputAdornment>}
      />
      {touched[name] && Boolean(errors[name]) && (
        <FormHelperText sx={{ color: "#F2323F" }}>
          {errors[name] as string}
        </FormHelperText>
      )}
    </FormInputWrapper>
  );
};

export const FormInputWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "4px",
  minHeight: "90px",
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: "#2C3659",
  fontSize: "16px",
  fontWeight: "500",
  marginBottom: "5px",
}));

const Field = styled(OutlinedInput)(({ theme }) => ({
  border: "1px solid #FFF",
  width: "100%",
  input: {
    color: theme.palette.text.secondary,
  },
  label: { color: theme.palette.secondary.dark },
  "& fieldset": {
    borderRadius: "8px",
  },
}));
