import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        ".icon-404": {
          width: { xs: "100%", sm: "600px", md: "600px" },
          height: { xs: "200px", sm: "300px", md: "400px" },
        },
      }}
    >
      <svg
        className="icon-404"
        width="685"
        height="433"
        viewBox="0 0 685 433"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_456_665)">
          <path
            d="M668.695 149.979C660.908 121.934 608.998 152.44 608.998 152.44L622.585 127.794C624.196 125.353 625.649 122.764 626.884 119.987C633.703 104.73 633.614 88.4143 626.844 74.1941C624.987 65.5671 619.344 53.2344 603.8 39.7158C574.381 14.151 475.749 -3.34011 447.2 0.543515C418.651 4.42715 473.16 16.4437 473.16 16.4437L468.841 16.4041C385.684 7.58939 302.013 6.45296 218.995 8.17243C198.174 8.60724 177.293 9.28909 156.916 12.8071C151.046 13.8151 144.742 15.3665 141.253 20.1C125.758 41.119 189.151 43.1646 199.093 42.7595C155.552 48.4218 107.17 50.1512 66.6738 66.9803C61.0213 69.3322 55.4281 72.109 51.169 76.4571C46.9098 80.8052 44.133 86.9617 45.1014 93.1577C47.8881 111.143 72.6623 116.469 86.7145 120.462C86.7145 120.462 5.95885 174.161 15.4752 201.415C19.4082 212.681 25.2782 224.539 36.1385 236.22C29.9425 243.997 24.4876 252.159 20.2779 260.786C17.6987 266.063 14.319 273.297 17.2046 279.364C31.909 310.325 254.975 416.586 433.721 386.258C481.204 378.204 528.499 364.883 570.122 341.315C611.547 317.865 653.763 279.137 664.198 230.29C664.544 228.68 664.791 227.118 664.969 225.606C671.442 203.095 675.227 173.449 668.714 149.989L668.695 149.979Z"
            fill="#407BFF"
          />
          <path
            opacity="0.8"
            d="M668.695 149.979C660.908 121.934 608.998 152.44 608.998 152.44L622.585 127.794C624.196 125.353 625.649 122.764 626.884 119.987C633.703 104.73 633.614 88.4143 626.844 74.1941C624.987 65.5671 619.344 53.2344 603.8 39.7158C574.381 14.151 475.749 -3.34011 447.2 0.543515C418.651 4.42715 473.16 16.4437 473.16 16.4437L468.841 16.4041C385.684 7.58939 302.013 6.45296 218.995 8.17243C198.174 8.60724 177.293 9.28909 156.916 12.8071C151.046 13.8151 144.742 15.3665 141.253 20.1C125.758 41.119 189.151 43.1646 199.093 42.7595C155.552 48.4218 107.17 50.1512 66.6738 66.9803C61.0213 69.3322 55.4281 72.109 51.169 76.4571C46.9098 80.8052 45.2497 86.8925 45.1014 93.1577C44.7062 109.74 71.348 115.896 86.7145 120.462C86.7145 120.462 5.95885 174.161 15.4752 201.415C19.4082 212.681 25.2782 224.539 36.1385 236.22C29.9425 243.997 24.4876 252.159 20.2779 260.786C17.6987 266.063 14.319 273.297 17.2046 279.364C31.909 310.325 254.975 416.586 433.721 386.258C481.204 378.204 528.499 364.883 570.122 341.315C611.547 317.865 653.763 279.137 664.198 230.29C664.544 228.68 664.791 227.118 664.969 225.606C671.442 203.095 675.227 173.449 668.714 149.989L668.695 149.979Z"
            fill="white"
          />
          <path
            d="M524.19 119.335C524.19 119.335 525.198 116.311 530.238 115.303C535.278 114.295 541.326 119.839 541.326 119.839C541.326 119.839 545.358 111.775 549.389 111.271C553.421 110.767 557.957 114.295 557.957 114.295"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M511.077 138.496C511.077 138.496 512.085 135.472 517.125 134.465C522.165 133.457 528.212 139 528.212 139C528.212 139 532.244 130.937 536.276 130.433C540.308 129.929 544.844 133.457 544.844 133.457"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M558.975 138.615C558.975 138.615 559.746 136.312 563.58 135.542C567.414 134.771 572.019 139 572.019 139C572.019 139 575.092 132.864 578.156 132.478C581.229 132.093 584.678 134.781 584.678 134.781"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M615.006 158.913C615.006 158.913 615.51 157.401 618.03 156.897C620.55 156.393 623.564 159.16 623.564 159.16C623.564 159.16 625.58 155.128 627.586 154.881C629.602 154.634 631.865 156.393 631.865 156.393"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M346.492 113.989C341.285 90.5785 314.297 73.1368 290.788 76.4769C284.928 77.307 279.226 79.5008 274.818 83.5524C268.81 89.0764 270.233 95.3515 267.268 102.2C266.438 104.097 264.837 105.787 262.792 106.143C256.942 107.141 252.228 101.31 246.309 101.271C241.882 101.241 236.219 103.682 233.749 107.506C231.446 111.064 233.047 117.28 228.916 119.641C224.292 122.29 218.659 117.26 213.649 119.078C207.433 121.341 210.259 125.857 207.996 128.693C205.733 131.52 201.217 130.393 194.991 129.82C188.776 129.257 183.686 136.599 183.686 136.599H379.854C379.854 136.599 381.554 133.773 374.201 122.468C366.849 111.163 356.681 119.078 352.155 119.078C347.629 119.078 346.502 113.999 346.502 113.989H346.492Z"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M401.12 76.3978C399.025 67.0099 388.204 60.0036 378.767 61.3475C376.415 61.6835 374.132 62.563 372.363 64.1837C369.952 66.3972 370.525 68.9171 369.33 71.6643C368.994 72.4252 368.351 73.1071 367.531 73.2455C365.189 73.6506 363.292 71.3086 360.92 71.2888C359.141 71.2789 356.868 72.2573 355.88 73.789C354.961 75.2219 355.604 77.7121 353.943 78.6608C352.086 79.7182 349.832 77.7022 347.817 78.4335C345.326 79.3427 346.453 81.1511 345.544 82.2875C344.635 83.4239 342.826 82.9694 340.326 82.7421C337.836 82.5148 335.79 85.4596 335.79 85.4596H414.49C414.49 85.4596 415.172 84.3232 412.217 79.7873C409.273 75.2515 405.191 78.4236 403.373 78.4236C401.555 78.4236 401.11 76.3879 401.1 76.3781L401.12 76.3978Z"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M569.025 191.484C566.93 182.096 556.109 175.089 546.672 176.433C544.32 176.769 542.037 177.649 540.268 179.27C537.857 181.483 538.43 184.003 537.235 186.75C536.899 187.511 536.256 188.193 535.436 188.331C533.094 188.737 531.197 186.394 528.825 186.375C527.046 186.365 524.773 187.343 523.785 188.875C522.866 190.308 523.508 192.798 521.848 193.747C519.99 194.804 517.737 192.788 515.721 193.519C513.231 194.429 514.358 196.237 513.449 197.373C512.539 198.51 510.731 198.055 508.231 197.828C505.741 197.601 503.695 200.546 503.695 200.546H582.395C582.395 200.546 583.077 199.409 580.122 194.873C577.178 190.337 573.096 193.51 571.278 193.51C569.46 193.51 569.015 191.474 569.005 191.464L569.025 191.484Z"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M442.782 181.206C439.581 166.818 422.999 156.106 408.551 158.162C404.954 158.675 401.446 160.019 398.738 162.51C395.052 165.909 395.922 169.763 394.094 173.963C393.59 175.129 392.602 176.167 391.337 176.384C387.74 176.997 384.844 173.419 381.208 173.39C378.49 173.37 375.012 174.872 373.49 177.224C372.077 179.408 373.065 183.232 370.515 184.685C367.669 186.306 364.211 183.222 361.137 184.339C357.313 185.732 359.052 188.509 357.659 190.248C356.266 191.988 353.489 191.286 349.664 190.94C345.84 190.594 342.717 195.11 342.717 195.11H463.258C463.258 195.11 464.296 193.371 459.779 186.424C455.263 179.477 449.008 184.339 446.231 184.339C443.454 184.339 442.763 181.216 442.753 181.216L442.782 181.206Z"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M471.134 346.918C471.134 346.918 463.93 339.457 465.956 339.674C467.982 339.892 471.766 345.485 471.766 345.485C471.766 345.485 471.816 333.182 473.614 335.88C475.413 338.577 473.199 345.544 473.199 345.544C473.199 345.544 478.338 337.53 478.328 338.834C478.288 342.886 474.365 346.058 474.365 346.058C474.365 346.058 482.597 339.961 481.243 342.431C479.899 344.902 474.632 347.244 474.632 347.244C474.632 347.244 484.119 345.327 480.749 346.681C477.379 348.025 474.168 348.953 474.168 348.953H471.549C471.549 348.953 462.596 344.457 465.966 345.129C469.335 345.801 471.134 346.928 471.134 346.928V346.918Z"
            fill="#263238"
          />
          <path
            d="M566.288 323.567C566.288 323.567 560.685 317.756 562.256 317.934C563.827 318.112 566.772 322.45 566.772 322.45C566.772 322.45 566.811 312.874 568.215 314.969C569.618 317.064 567.898 322.489 567.898 322.489C567.898 322.489 571.901 316.254 571.891 317.272C571.861 320.424 568.808 322.895 568.808 322.895C568.808 322.895 575.211 318.151 574.164 320.078C573.116 322.005 569.015 323.824 569.015 323.824C569.015 323.824 576.397 322.331 573.778 323.379C571.159 324.426 568.659 325.148 568.659 325.148H566.624C566.624 325.148 559.657 321.65 562.276 322.173C564.894 322.697 566.298 323.577 566.298 323.577L566.288 323.567Z"
            fill="#263238"
          />
          <path
            d="M654.623 302.725C654.623 302.725 649.02 296.915 650.591 297.093C652.162 297.271 655.107 301.609 655.107 301.609C655.107 301.609 655.147 292.033 656.55 294.128C657.953 296.223 656.234 301.648 656.234 301.648C656.234 301.648 660.236 295.413 660.226 296.431C660.196 299.583 657.143 302.053 657.143 302.053C657.143 302.053 663.546 297.31 662.499 299.237C661.451 301.164 657.35 302.982 657.35 302.982C657.35 302.982 664.732 301.49 662.113 302.538C659.495 303.585 656.994 304.307 656.994 304.307H654.959C654.959 304.307 647.992 300.808 650.611 301.332C653.229 301.856 654.633 302.735 654.633 302.735L654.623 302.725Z"
            fill="#263238"
          />
          <path
            d="M542.037 350.327C542.037 350.327 535.337 343.39 537.215 343.597C539.092 343.805 542.61 348.993 542.61 348.993C542.61 348.993 542.66 337.55 544.33 340.06C546 342.57 543.944 349.042 543.944 349.042C543.944 349.042 548.717 341.591 548.708 342.807C548.678 346.572 545.031 349.517 545.031 349.517C545.031 349.517 552.68 343.854 551.425 346.147C550.17 348.45 545.279 350.614 545.279 350.614C545.279 350.614 554.093 348.835 550.961 350.09C547.828 351.345 544.844 352.205 544.844 352.205H542.403C542.403 352.205 534.072 348.025 537.205 348.657C540.338 349.28 542.008 350.327 542.008 350.327H542.037Z"
            fill="#407BFF"
          />
          <path
            d="M409.49 320.217L350.238 253.78L337.253 264.166L272.288 166.344L215.734 178.953L198.826 147.361L182.382 146.491L151.234 85.0643L104.65 127.567L82.1787 119.553L0.355743 202.986"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M420.607 332.678L414.392 325.711"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M185.366 187.076L180.465 188.4"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M215.615 178.884L190.881 185.584"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M122.29 150.444L136.144 129.998L135.205 122.982L151.106 90.7168"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M116.509 158.992L119.977 153.863"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M95.0055 171.087L72.5437 201.544"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M83.3052 122.982L88.9182 135.611L101.537 145.898L100.134 164.13L97.0807 168.281"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M198.826 147.361L187.116 158.517L182.906 151.501"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M671.481 272.348L580.547 208.214L552.947 247.179L518.844 235.814L466.074 309.692L431.972 302.389L414.925 317.005"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M580.547 208.214L569.736 255.737L556.989 250.153"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M519.141 238.601L492.44 316.293L474.118 313.111"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M517.905 233.047C494.238 233.047 475.057 252.1 475.057 275.599C475.057 286.499 479.178 296.431 485.967 303.961C489.07 303.516 494.722 303.2 500.978 305.324C509.259 308.141 514.417 312.756 515.553 313.833H519.398C520.524 312.756 525.692 308.141 533.973 305.324C540.782 303.012 546.879 303.585 549.745 304.079C556.593 296.539 560.764 286.549 560.764 275.599C560.764 252.1 541.583 233.047 517.915 233.047H517.905Z"
            fill="#407BFF"
          />
          <path
            d="M537.847 292.675C536.533 293.318 535.219 293.97 533.934 294.662C532.639 295.363 531.375 296.134 530.189 296.974C529.744 297.28 529.299 297.607 528.864 297.923L535.881 284.849L526.246 299.929C525.267 300.71 524.309 301.52 523.37 302.33C521.819 303.684 520.307 305.068 518.824 306.481L518.34 292.3L528.42 276.963L518.271 290.156L517.836 277.457L517.115 291.667L516.393 305.907C515.109 304.692 513.794 303.496 512.451 302.33C511.393 301.411 510.316 300.512 509.219 299.632L500.108 284.068L506.798 297.794C506.413 297.518 506.027 297.231 505.642 296.964C504.456 296.124 503.191 295.363 501.897 294.652C500.612 293.96 499.298 293.308 497.983 292.666C495.345 291.401 492.657 290.215 489.88 289.237C492.578 290.383 495.167 291.717 497.697 293.13C498.962 293.842 500.207 294.573 501.432 295.334C502.638 296.095 503.804 296.905 504.901 297.794C507.124 299.553 509.189 301.47 511.186 303.447C512.935 305.176 514.625 306.975 516.245 308.793L515.326 326.867H519.546L518.953 309.534C520.761 307.459 522.688 305.423 524.665 303.457C526.661 301.48 528.736 299.553 530.95 297.804C532.056 296.915 533.213 296.105 534.418 295.344C535.644 294.583 536.889 293.851 538.154 293.14C540.683 291.727 543.272 290.383 545.97 289.246C543.193 290.225 540.505 291.411 537.867 292.675H537.847Z"
            fill="#263238"
          />
          <path
            d="M188.944 291.44C188.944 303.664 182.086 304.593 173.627 304.593C165.168 304.593 158.31 303.674 158.31 291.44C158.31 279.206 165.168 253.651 173.627 253.651C182.086 253.651 188.944 279.206 188.944 291.44Z"
            fill="#407BFF"
          />
          <path
            d="M178.805 296.994L176.275 297.162L182.876 292.715L178.162 295.097L178.805 293.239L176.65 295.857L174.121 297.142L173.923 288.357L175.267 285.106L173.893 286.934L173.775 281.845L177.975 276.252L173.745 280.58L173.488 269.374L173.261 276.538L170.573 273.544L173.221 277.922L172.866 289.276L170.524 287.744L168.379 283.96L169.239 286.914L165.049 284.167L172.816 290.867L171.64 328.152H174.832L174.16 298.595L174.901 298.091L178.805 296.994Z"
            fill="#263238"
          />
          <path
            d="M600.835 249.333C600.835 273.228 587.435 275.026 570.912 275.026C554.39 275.026 540.99 273.218 540.99 249.333C540.99 225.448 554.39 175.504 570.912 175.504C587.435 175.504 600.835 225.448 600.835 249.333Z"
            fill="#407BFF"
          />
          <path
            opacity="0.31"
            d="M600.835 249.333C600.835 273.228 587.435 275.026 570.912 275.026C554.39 275.026 540.99 273.218 540.99 249.333C540.99 225.448 554.39 175.504 570.912 175.504C587.435 175.504 600.835 225.448 600.835 249.333Z"
            fill="white"
          />
          <path
            d="M581.032 260.174L576.081 260.519L588.967 251.823L579.767 256.478L581.032 252.841L576.822 257.97L571.881 260.46L571.486 243.305L574.104 236.961L571.426 240.528L571.199 230.577L579.401 219.647L571.14 228.106L570.646 206.208L570.201 220.221L564.944 214.351L570.112 222.908L569.41 245.113L564.835 242.119L560.665 234.717L562.335 240.479L554.143 235.123L569.321 248.206L567.019 321.066H573.264L571.95 263.306L573.393 262.328L581.032 260.174Z"
            fill="#263238"
          />
          <path
            d="M664.89 258.741C664.89 270.965 658.032 271.894 649.573 271.894C641.114 271.894 634.256 270.975 634.256 258.741C634.256 246.507 641.114 220.952 649.573 220.952C658.032 220.952 664.89 246.507 664.89 258.741Z"
            fill="#407BFF"
          />
          <path
            opacity="0.5"
            d="M664.89 258.741C664.89 270.965 658.032 271.894 649.573 271.894C641.114 271.894 634.256 270.975 634.256 258.741C634.256 246.507 641.114 220.952 649.573 220.952C658.032 220.952 664.89 246.507 664.89 258.741Z"
            fill="white"
          />
          <path
            d="M654.751 264.284L652.221 264.462L658.813 260.015L654.099 262.397L654.751 260.529L652.597 263.158L650.067 264.433L649.87 255.657L651.204 252.406L649.84 254.234L649.721 249.145L653.921 243.542L649.692 247.87L649.435 236.664L649.207 243.839L646.52 240.834L649.168 245.212L648.812 256.576L646.46 255.045L644.326 251.26L645.186 254.205L640.996 251.467L648.753 258.167L647.587 295.452H650.779L650.107 265.885L650.838 265.391L654.751 264.284Z"
            fill="#263238"
          />
          <path
            d="M40.6743 248.404C40.6743 260.628 33.8162 261.557 25.3572 261.557C16.8982 261.557 10.0401 260.638 10.0401 248.404C10.0401 236.17 16.8982 210.615 25.3572 210.615C33.8162 210.615 40.6743 236.17 40.6743 248.404Z"
            fill="#407BFF"
          />
          <path
            opacity="0.5"
            d="M40.6743 248.404C40.6743 260.628 33.8162 261.557 25.3572 261.557C16.8982 261.557 10.0401 260.638 10.0401 248.404C10.0401 236.17 16.8982 210.615 25.3572 210.615C33.8162 210.615 40.6743 236.17 40.6743 248.404Z"
            fill="white"
          />
          <path
            d="M30.5354 253.948L28.0056 254.126L34.5969 249.679L29.8832 252.06L30.5354 250.193L28.3811 252.821L25.8513 254.096L25.6537 245.311L26.9877 242.07L25.624 243.898L25.5054 238.799L29.7053 233.206L25.4758 237.534L25.2189 226.328L24.9916 233.502L22.3037 230.498L24.952 234.876L24.5963 246.24L22.2444 244.708L20.1099 240.923L20.9696 243.868L16.7796 241.131L24.537 247.821L23.3709 285.106H26.5628L25.8908 255.549L26.6221 255.055L30.5354 253.948Z"
            fill="#263238"
          />
          <path
            d="M116.558 220.675C92.8907 220.675 73.7098 239.728 73.7098 263.227C73.7098 274.127 77.8306 284.058 84.6195 291.588C87.7224 291.144 93.3749 290.828 99.6303 292.952C107.911 295.769 113.07 300.383 114.206 301.461H118.05C119.177 300.383 124.345 295.769 132.626 292.952C139.435 290.64 145.532 291.213 148.398 291.707C155.246 284.167 159.416 274.176 159.416 263.227C159.416 239.728 140.235 220.675 116.568 220.675H116.558Z"
            fill="#407BFF"
          />
          <path
            opacity="0.33"
            d="M116.558 220.675C92.8907 220.675 73.7098 239.728 73.7098 263.227C73.7098 274.127 77.8306 284.058 84.6195 291.588C87.7224 291.144 93.3749 290.828 99.6303 292.952C107.911 295.769 113.07 300.383 114.206 301.461H118.05C119.177 300.383 124.345 295.769 132.626 292.952C139.435 290.64 145.532 291.213 148.398 291.707C155.246 284.167 159.416 274.176 159.416 263.227C159.416 239.728 140.235 220.675 116.568 220.675H116.558Z"
            fill="white"
          />
          <path
            d="M136.5 280.293C135.186 280.936 133.871 281.588 132.587 282.28C131.292 282.981 130.027 283.752 128.841 284.592C128.397 284.898 127.952 285.224 127.517 285.541L134.533 272.467L124.899 287.547C123.92 288.327 122.962 289.138 122.023 289.948C120.471 291.302 118.959 292.685 117.477 294.108L116.993 279.928L127.073 264.581L116.924 277.773L116.489 265.075L115.768 279.285L115.046 293.525C113.762 292.31 112.447 291.114 111.103 289.948C110.046 289.029 108.969 288.13 107.872 287.25L98.7606 271.686L105.451 285.412C105.065 285.135 104.68 284.849 104.295 284.582C103.109 283.742 101.844 282.981 100.549 282.27C99.2646 281.578 97.9503 280.926 96.636 280.283C93.9975 279.019 91.3096 277.833 88.5328 276.854C91.2306 278.001 93.8196 279.335 96.3494 280.748C97.6143 281.459 98.8595 282.191 100.085 282.952C101.29 283.712 102.457 284.523 103.553 285.412C105.777 287.171 107.842 289.088 109.838 291.065C111.587 292.794 113.277 294.593 114.898 296.411L113.979 314.485H118.199L117.596 297.152C119.404 295.077 121.331 293.041 123.308 291.075C125.304 289.098 127.379 287.171 129.592 285.422C130.699 284.533 131.855 283.722 133.061 282.961C134.286 282.201 135.532 281.469 136.796 280.758C139.326 279.345 141.915 278.001 144.613 276.864C141.836 277.843 139.148 279.028 136.51 280.293H136.5Z"
            fill="#263238"
          />
          <path
            d="M235.518 347.659V308.447H184.487V283.347L228.254 179.625H265.598V283.969H281.775V308.447H265.598V347.659H235.518ZM209.795 283.969H235.518V212.611L209.795 283.969Z"
            fill="#263238"
          />
          <path
            d="M341.937 347.748C331.837 347.748 323.477 345.673 316.836 341.522C310.196 337.372 305.215 331.67 301.905 324.407C298.584 317.143 296.924 308.744 296.924 299.198V223.897C296.924 214.074 298.515 205.467 301.697 198.065C304.879 190.663 309.791 184.892 316.421 180.742C323.062 176.591 331.561 174.516 341.937 174.516C352.313 174.516 360.574 176.591 367.146 180.742C373.717 184.892 378.589 190.663 381.771 198.065C384.953 205.467 386.544 214.074 386.544 223.897V299.198C386.544 308.744 384.884 317.143 381.564 324.407C378.243 331.67 373.263 337.372 366.622 341.522C359.981 345.673 351.75 347.748 341.937 347.748ZM341.937 319.535C345.672 319.535 348.37 318.359 350.03 316.007C351.69 313.655 352.797 310.858 353.35 307.607C353.904 304.356 354.181 301.352 354.181 298.585V224.529C354.181 221.485 353.933 218.303 353.459 214.983C352.975 211.663 351.908 208.797 350.247 206.376C348.587 203.955 345.82 202.749 341.947 202.749C338.073 202.749 335.276 203.965 333.547 206.376C331.818 208.797 330.711 211.663 330.227 214.983C329.742 218.303 329.505 221.485 329.505 224.529V298.585C329.505 301.352 329.782 304.366 330.335 307.607C330.889 310.858 332.065 313.655 333.863 316.007C335.662 318.359 338.359 319.535 341.957 319.535H341.937Z"
            fill="#263238"
          />
          <path
            d="M454.997 347.659V308.447H403.966V283.347L447.733 179.625H485.077V283.969H501.254V308.447H485.077V347.659H454.997ZM429.274 283.969H454.997V212.611L429.274 283.969Z"
            fill="#263238"
          />
          <path
            d="M670.394 275.066C602.387 312.756 482.241 337.797 345.376 337.797C194.023 337.797 63.1163 307.172 0.553406 262.684"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M684.723 266.429C681.808 268.346 678.774 270.233 675.602 272.091"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M643.179 256.754C643.179 281.736 629.167 283.624 611.893 283.624C594.619 283.624 580.607 281.736 580.607 256.754C580.607 231.773 594.619 179.546 611.893 179.546C629.167 179.546 643.179 231.773 643.179 256.754Z"
            fill="#407BFF"
          />
          <path
            d="M622.467 268.089L617.298 268.445L630.778 259.353L621.143 264.225L622.467 260.421L618.069 265.777L612.901 268.385L612.496 250.45L615.233 243.809L612.427 247.544L612.19 237.139L620.767 225.705L612.13 234.549L611.606 211.653L611.152 226.308L605.648 220.171L611.063 229.124L610.322 252.337L605.539 249.204L601.171 241.467L602.92 247.495L594.362 241.892L610.223 255.578L607.822 331.759H614.354L612.97 271.36L614.482 270.342L622.467 268.089Z"
            fill="#263238"
          />
          <path
            d="M609.778 332.431C609.778 332.431 598.226 320.464 601.468 320.829C604.709 321.185 610.776 330.138 610.776 330.138C610.776 330.138 610.855 310.414 613.741 314.732C616.627 319.051 613.079 330.217 613.079 330.217C613.079 330.217 621.32 317.371 621.301 319.466C621.241 325.958 614.956 331.037 614.956 331.037C614.956 331.037 628.149 321.264 625.985 325.227C623.821 329.189 615.391 332.935 615.391 332.935C615.391 332.935 630.6 329.871 625.194 332.026C619.789 334.19 614.65 335.672 614.65 335.672H610.45C610.45 335.672 596.092 328.468 601.497 329.545C606.903 330.622 609.778 332.431 609.778 332.431Z"
            fill="#263238"
          />
          <path
            d="M56.5448 317.578C56.5448 317.578 46.5541 307.232 49.3606 307.538C52.167 307.854 57.4144 315.592 57.4144 315.592C57.4144 315.592 57.4836 298.535 59.9837 302.271C62.474 306.006 59.4105 315.661 59.4105 315.661C59.4105 315.661 66.5355 304.544 66.5157 306.362C66.4663 311.975 61.0312 316.373 61.0312 316.373C61.0312 316.373 72.435 307.923 70.5673 311.352C68.6996 314.782 61.4067 318.013 61.4067 318.013C61.4067 318.013 74.5596 315.365 69.8855 317.232C65.2113 319.1 60.7644 320.385 60.7644 320.385H57.1278C57.1278 320.385 44.7061 314.159 49.3803 315.088C54.0545 316.027 56.5448 317.578 56.5448 317.578Z"
            fill="#263238"
          />
          <path
            d="M92.6437 243.137C92.6437 268.148 78.6211 270.036 61.3178 270.036C44.0144 270.036 29.9919 268.148 29.9919 243.137C29.9919 218.126 44.0144 165.85 61.3178 165.85C78.6211 165.85 92.6437 218.126 92.6437 243.137Z"
            fill="#407BFF"
          />
          <path
            d="M71.9113 254.481L66.7331 254.837L80.2319 245.736L70.5871 250.608L71.9113 246.803L67.5039 252.169L62.3356 254.778L61.9305 236.822L64.6678 230.182L61.8613 233.917L61.6241 223.501L70.2116 212.058L61.5648 220.902L61.0411 197.986L60.5766 212.651L55.0724 206.514L60.4877 215.477L59.7564 238.71L54.9637 235.577L50.5958 227.83L52.3449 233.868L43.7674 228.255L59.6576 241.951L57.2464 318.22H63.7883L62.4048 257.752L63.9167 256.735L71.9113 254.481Z"
            fill="#263238"
          />
          <path
            d="M165.899 343.578C165.899 343.578 155.908 333.231 158.715 333.538C161.521 333.854 166.769 341.591 166.769 341.591C166.769 341.591 166.838 324.535 169.338 328.27C171.828 332.006 168.765 341.661 168.765 341.661C168.765 341.661 175.89 330.543 175.87 332.362C175.82 337.975 170.385 342.372 170.385 342.372C170.385 342.372 181.789 333.923 179.921 337.352C178.054 340.781 170.761 344.012 170.761 344.012C170.761 344.012 183.914 341.364 179.24 343.232C174.565 345.099 170.119 346.384 170.119 346.384H166.482C166.482 346.384 154.06 340.159 158.734 341.087C163.409 342.026 165.899 343.578 165.899 343.578Z"
            fill="#263238"
          />
          <path
            d="M218.916 366.257C218.916 366.257 208.925 355.91 211.732 356.217C214.538 356.533 219.785 364.271 219.785 364.271C219.785 364.271 219.855 347.214 222.355 350.95C224.845 354.685 221.782 364.34 221.782 364.34C221.782 364.34 228.906 353.223 228.887 355.041C228.837 360.654 223.402 365.051 223.402 365.051C223.402 365.051 234.806 356.602 232.938 360.031C231.071 363.46 223.778 366.692 223.778 366.692C223.778 366.692 236.931 364.043 232.256 365.911C227.582 367.779 223.135 369.063 223.135 369.063H219.499C219.499 369.063 207.077 362.838 211.751 363.767C216.426 364.696 218.916 366.257 218.916 366.257Z"
            fill="#263238"
          />
          <path
            d="M361.118 351.029C361.118 351.029 353.914 343.568 355.939 343.785C357.965 344.012 361.75 349.596 361.75 349.596C361.75 349.596 361.8 337.293 363.598 339.99C365.397 342.688 363.183 349.655 363.183 349.655C363.183 349.655 368.322 341.641 368.312 342.945C368.272 346.997 364.349 350.169 364.349 350.169C364.349 350.169 372.581 344.072 371.227 346.542C369.883 349.013 364.616 351.355 364.616 351.355C364.616 351.355 374.103 349.438 370.733 350.792C367.363 352.145 364.151 353.064 364.151 353.064H361.533C361.533 353.064 352.58 348.568 355.949 349.24C359.319 349.912 361.118 351.039 361.118 351.039V351.029Z"
            fill="#263238"
          />
          <path
            d="M640.808 333.162C640.808 333.162 635.205 327.351 636.776 327.529C638.347 327.707 641.292 332.045 641.292 332.045C641.292 332.045 641.331 322.47 642.735 324.565C644.138 326.66 642.419 332.085 642.419 332.085C642.419 332.085 646.421 325.849 646.411 326.867C646.381 330.02 643.328 332.49 643.328 332.49C643.328 332.49 649.731 327.747 648.684 329.674C647.636 331.601 643.535 333.419 643.535 333.419C643.535 333.419 650.917 331.927 648.298 332.974C645.68 334.022 643.179 334.743 643.179 334.743H641.144C641.144 334.743 634.177 331.245 636.796 331.769C639.414 332.292 640.818 333.172 640.818 333.172L640.808 333.162Z"
            fill="#263238"
          />
          <path
            d="M263.246 351.068C263.246 351.068 253.256 340.722 256.062 341.028C258.869 341.344 264.116 349.082 264.116 349.082C264.116 349.082 264.185 332.026 266.685 335.761C269.176 339.496 266.112 349.151 266.112 349.151C266.112 349.151 273.237 338.034 273.217 339.852C273.168 345.465 267.733 349.863 267.733 349.863C267.733 349.863 279.137 341.414 277.269 344.843C275.401 348.272 268.108 351.503 268.108 351.503C268.108 351.503 281.261 348.855 276.587 350.722C271.913 352.59 267.466 353.875 267.466 353.875H263.829C263.829 353.875 251.408 347.649 256.082 348.578C260.756 349.517 263.246 351.068 263.246 351.068Z"
            fill="#407BFF"
          />
          <path
            d="M191.167 344.19C191.167 344.19 184.467 337.253 186.345 337.461C188.222 337.668 191.74 342.856 191.74 342.856C191.74 342.856 191.79 331.413 193.46 333.923C195.13 336.433 193.074 342.906 193.074 342.906C193.074 342.906 197.847 335.455 197.837 336.67C197.808 340.435 194.161 343.38 194.161 343.38C194.161 343.38 201.81 337.718 200.555 340.01C199.3 342.313 194.408 344.477 194.408 344.477C194.408 344.477 203.223 342.698 200.091 343.953C196.958 345.208 193.974 346.068 193.974 346.068H191.533C191.533 346.068 183.202 341.888 186.335 342.52C189.467 343.143 191.138 344.19 191.138 344.19H191.167Z"
            fill="#407BFF"
          />
          <path
            d="M107.467 371.603C107.467 371.603 100.767 364.666 102.644 364.873C104.522 365.081 108.04 370.269 108.04 370.269C108.04 370.269 108.089 358.826 109.759 361.336C111.429 363.846 109.374 370.318 109.374 370.318C109.374 370.318 114.147 362.867 114.137 364.083C114.107 367.848 110.461 370.793 110.461 370.793C110.461 370.793 118.11 365.13 116.855 367.423C115.6 369.725 110.708 371.89 110.708 371.89C110.708 371.89 119.523 370.111 116.39 371.366C113.258 372.621 110.273 373.481 110.273 373.481H107.832C107.832 373.481 99.5018 369.301 102.634 369.933C105.767 370.556 107.437 371.603 107.437 371.603H107.467Z"
            fill="#407BFF"
          />
          <path
            d="M331.561 372.947C331.561 372.947 324.861 366.01 326.738 366.217C328.616 366.425 332.134 371.613 332.134 371.613C332.134 371.613 332.183 360.17 333.853 362.68C335.523 365.19 333.468 371.662 333.468 371.662C333.468 371.662 338.241 364.211 338.231 365.427C338.201 369.192 334.555 372.137 334.555 372.137C334.555 372.137 342.204 366.474 340.949 368.767C339.694 371.069 334.802 373.234 334.802 373.234C334.802 373.234 343.617 371.455 340.484 372.71C337.351 373.965 334.367 374.825 334.367 374.825H331.926C331.926 374.825 323.596 370.645 326.728 371.277C329.861 371.9 331.531 372.947 331.531 372.947H331.561Z"
            fill="#407BFF"
          />
          <path
            d="M440.233 373.313C440.233 373.313 433.533 366.375 435.41 366.583C437.288 366.791 440.806 371.979 440.806 371.979C440.806 371.979 440.855 360.535 442.526 363.045C444.196 365.555 442.14 372.028 442.14 372.028C442.14 372.028 446.913 364.577 446.903 365.792C446.874 369.558 443.227 372.502 443.227 372.502C443.227 372.502 450.876 366.84 449.621 369.133C448.366 371.435 443.474 373.599 443.474 373.599C443.474 373.599 452.289 371.82 449.156 373.075C446.024 374.331 443.039 375.19 443.039 375.19H440.599C440.599 375.19 432.268 371.01 435.401 371.643C438.533 372.265 440.203 373.313 440.203 373.313H440.233Z"
            fill="#407BFF"
          />
          <path
            d="M642.29 351.602C642.29 351.602 632.092 341.038 634.958 341.354C637.823 341.67 643.179 349.576 643.179 349.576C643.179 349.576 643.258 332.154 645.798 335.978C648.338 339.793 645.215 349.655 645.215 349.655C645.215 349.655 652.488 338.311 652.468 340.158C652.419 345.89 646.865 350.376 646.865 350.376C646.865 350.376 658.516 341.749 656.609 345.248C654.702 348.746 647.251 352.056 647.251 352.056C647.251 352.056 660.68 349.349 655.907 351.256C651.134 353.163 646.599 354.478 646.599 354.478H642.893C642.893 354.478 630.214 348.114 634.987 349.072C639.76 350.031 642.3 351.612 642.3 351.612L642.29 351.602Z"
            fill="#407BFF"
          />
          <path
            d="M34.6166 351.602C34.6166 351.602 24.4184 341.038 27.2842 341.354C30.15 341.67 35.506 349.576 35.506 349.576C35.506 349.576 35.5851 332.154 38.1247 335.978C40.6644 339.803 37.5417 349.655 37.5417 349.655C37.5417 349.655 44.8148 338.311 44.7951 340.158C44.7457 345.89 39.192 350.376 39.192 350.376C39.192 350.376 50.8429 341.749 48.9356 345.248C47.0284 348.746 39.5774 352.056 39.5774 352.056C39.5774 352.056 53.007 349.349 48.234 351.256C43.461 353.163 38.9252 354.478 38.9252 354.478H35.2194C35.2194 354.478 22.5408 348.114 27.3138 349.072C32.0868 350.031 34.6265 351.612 34.6265 351.612L34.6166 351.602Z"
            fill="#407BFF"
          />
          <path
            d="M84.9259 336.68C84.9259 336.68 74.4905 318.685 77.4649 317.292C80.4394 315.898 86.5959 329.595 86.5959 329.595C86.5959 329.595 85.4101 303.793 88.1869 306.174C90.9638 308.556 93.543 326.818 93.543 326.818C93.543 326.818 99.0966 306.975 100.885 311.135C102.674 315.305 99.4623 327.915 99.4623 327.915C99.4623 327.915 107.437 312.726 108.237 317.687C109.028 322.648 104.463 330.78 104.463 330.78C104.463 330.78 114.977 320.266 115.777 324.426C116.568 328.597 113.001 332.757 113.001 332.757C113.001 332.757 126.895 325.217 123.515 329.387C120.145 333.557 115.185 338.913 115.185 338.913C115.185 338.913 128.486 334.15 126.895 336.927C125.304 339.704 119.355 342.678 119.355 342.678C119.355 342.678 130.274 340.692 129.079 342.481C127.893 344.269 125.116 346.019 125.116 346.019H75.6961C75.6961 346.019 62.6024 341.285 67.7608 340.89C72.9192 340.494 77.6824 342.678 77.6824 342.678C77.6824 342.678 61.6044 331.759 69.1443 330.968C76.6843 330.178 82.8408 339.704 82.8408 339.704C82.8408 339.704 72.1287 324.624 75.8937 327.006L84.9456 336.67L84.9259 336.68Z"
            fill="#407BFF"
          />
          <path
            d="M85.5978 341.799C85.5978 341.799 72.1188 327.836 75.9036 328.261C79.6884 328.685 86.7639 339.121 86.7639 339.121C86.7639 339.121 86.8627 316.106 90.2226 321.146C93.5825 326.185 89.4518 339.21 89.4518 339.21C89.4518 339.21 99.067 324.219 99.0373 326.67C98.9682 334.249 91.6357 340.168 91.6357 340.168C91.6357 340.168 107.022 328.765 104.502 333.389C101.982 338.014 92.1397 342.382 92.1397 342.382C92.1397 342.382 109.878 338.805 103.573 341.325C97.2684 343.844 91.2701 345.574 91.2701 345.574H86.3686C86.3686 345.574 69.6186 337.164 75.9233 338.429C82.2281 339.694 85.5879 341.789 85.5879 341.789L85.5978 341.799Z"
            fill="#263238"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M529.369 368.352C529.369 368.352 509.644 334.328 515.267 331.709C520.89 329.081 532.521 354.962 532.521 354.962C532.521 354.962 530.268 306.194 535.525 310.7C540.782 315.206 545.654 349.714 545.654 349.714C545.654 349.714 556.159 312.202 559.528 320.078C562.908 327.954 556.831 351.79 556.831 351.79C556.831 351.79 571.91 323.082 573.413 332.451C574.915 341.829 566.288 357.205 566.288 357.205C566.288 357.205 586.17 337.322 587.672 345.198C589.174 353.074 582.425 360.95 582.425 360.95C582.425 360.95 608.681 346.7 602.308 354.576C595.934 362.452 586.556 372.581 586.556 372.581C586.556 372.581 611.686 363.579 608.691 368.826C605.687 374.074 594.441 379.706 594.441 379.706C594.441 379.706 615.075 375.951 612.822 379.331C610.569 382.71 605.331 386.021 605.331 386.021H511.917C511.917 386.021 487.163 377.078 496.916 376.327C506.67 375.576 515.672 379.706 515.672 379.706C515.672 379.706 485.285 359.073 499.545 357.571C513.794 356.069 525.426 374.074 525.426 374.074C525.426 374.074 505.167 345.564 512.292 350.07L529.398 368.332L529.369 368.352Z"
            fill="#407BFF"
          />
          <path
            d="M530.643 378.026C530.643 378.026 505.177 351.641 512.322 352.432C519.467 353.223 532.847 372.957 532.847 372.957C532.847 372.957 533.035 329.456 539.389 338.983C545.743 348.519 537.936 373.135 537.936 373.135C537.936 373.135 556.099 344.793 556.06 349.428C555.931 363.747 542.067 374.943 542.067 374.943C542.067 374.943 571.159 353.4 566.386 362.136C561.623 370.872 543.025 379.133 543.025 379.133C543.025 379.133 576.555 372.374 564.647 377.137C552.739 381.9 541.395 385.171 541.395 385.171H532.126C532.126 385.171 500.464 369.281 512.381 371.672C524.299 374.054 530.653 378.026 530.653 378.026H530.643Z"
            fill="#263238"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M118.92 337.698C118.92 337.698 120.936 335.87 122.665 335.87C124.395 335.87 129.681 338.409 129.681 338.409C129.681 338.409 131.796 341.67 131.984 341.858C132.172 342.046 134.771 343.163 134.958 343.617C135.146 344.072 135.532 345.574 135.532 345.574H119.384L116.4 337.757L118.9 337.688L118.92 337.698Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M122.665 335.88L122.566 337.421C122.566 337.421 126.697 339.823 126.598 340.692C126.499 341.562 125.64 343.578 125.64 343.578L127.754 345.791H116.509L117.378 339.16C117.378 339.16 119.78 335.702 122.665 335.889V335.88Z"
            fill="#263238"
          />
          <path
            d="M97.8713 336.739C97.8713 336.739 105.945 324.634 107.773 324.436C109.601 324.248 113.445 325.78 114.888 328.28C116.331 330.781 117.675 335.682 117.774 335.969C117.872 336.255 118.258 336.453 120.461 340C122.665 343.548 122.576 345.574 122.576 345.574H91.3393C91.3393 345.574 92.0112 342.402 93.1674 340.574C94.3236 338.745 97.0115 338.073 97.0115 338.073L97.8811 336.729L97.8713 336.739Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M110.263 345.396C110.263 345.396 109.394 343.281 109.305 342.995C109.206 342.708 109.206 339.921 108.919 338.765C108.633 337.609 106.231 334.051 106.231 334.051L107.961 329.051L107.091 328.379L108.534 324.822L107.723 324.446C105.826 324.782 97.8613 336.739 97.8613 336.739L96.9916 338.083C96.9916 338.083 94.3037 338.755 93.1476 340.583C92.1594 342.145 91.5269 344.684 91.3688 345.386H110.263V345.396Z"
            fill="#263238"
          />
          <path
            d="M430.193 340.712C430.193 340.712 431.458 339.566 432.545 339.566C433.632 339.566 436.942 341.157 436.942 341.157C436.942 341.157 438.266 343.202 438.385 343.321C438.504 343.44 440.134 344.141 440.253 344.428C440.371 344.714 440.618 345.653 440.618 345.653H430.499L428.631 340.752L430.203 340.712H430.193Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M432.545 339.566L432.485 340.534C432.485 340.534 435.075 342.036 435.015 342.58C434.956 343.123 434.412 344.388 434.412 344.388L435.737 345.771H428.691L429.234 341.611C429.234 341.611 430.736 339.437 432.545 339.566Z"
            fill="#263238"
          />
          <path
            d="M417 340.109C417 340.109 422.06 332.52 423.206 332.391C424.353 332.273 426.764 333.231 427.663 334.802C428.562 336.374 429.412 339.447 429.471 339.625C429.531 339.803 429.768 339.921 431.161 342.155C432.545 344.388 432.485 345.653 432.485 345.653H412.899C412.899 345.653 413.324 343.667 414.046 342.52C414.767 341.374 416.457 340.949 416.457 340.949L417 340.109Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M424.768 345.534C424.768 345.534 424.224 344.21 424.165 344.032C424.106 343.854 424.105 342.105 423.928 341.384C423.75 340.662 422.238 338.429 422.238 338.429L423.325 335.297L422.781 334.872L423.681 332.638L423.177 332.401C421.991 332.619 416.99 340.109 416.99 340.109L416.447 340.949C416.447 340.949 414.757 341.374 414.036 342.52C413.413 343.499 413.018 345.09 412.919 345.534H424.768Z"
            fill="#263238"
          />
          <path
            d="M593.621 370.279C593.621 370.279 597.436 366.83 600.707 366.83C603.978 366.83 613.968 371.633 613.968 371.633C613.968 371.633 617.961 377.789 618.326 378.155C618.692 378.52 623.593 380.615 623.959 381.475C624.325 382.335 625.046 385.171 625.046 385.171H594.53L588.898 370.397L593.621 370.279Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M600.707 366.83L600.529 369.735C600.529 369.735 608.336 374.281 608.158 375.912C607.98 377.542 606.339 381.357 606.339 381.357L610.332 385.537H589.076L590.706 373.006C590.706 373.006 595.252 366.464 600.697 366.83H600.707Z"
            fill="#263238"
          />
          <path
            d="M553.846 368.461C553.846 368.461 569.104 345.574 572.553 345.208C576.002 344.843 583.275 347.748 585.992 352.471C588.72 357.195 591.259 366.455 591.437 367.008C591.615 367.551 592.346 367.917 596.527 374.637C600.707 381.357 600.519 385.171 600.519 385.171H541.484C541.484 385.171 542.759 379.173 544.933 375.724C547.117 372.275 552.196 371 552.196 371L553.826 368.461H553.846Z"
            fill="white"
            stroke="#263238"
            stroke-miterlimit="10"
          />
          <path
            d="M577.276 384.815C577.276 384.815 575.646 380.823 575.458 380.27C575.28 379.726 575.28 374.459 574.727 372.275C574.183 370.091 569.638 363.371 569.638 363.371L572.909 353.924L571.278 352.649L574.005 345.93L572.484 345.218C568.906 345.86 553.846 368.451 553.846 368.451L552.216 370.99C552.216 370.99 547.126 372.265 544.952 375.714C543.085 378.669 541.889 383.461 541.583 384.796H577.286L577.276 384.815Z"
            fill="#263238"
          />
        </g>
        <defs>
          <clipPath id="clip0_456_665">
            <rect width="685" height="433" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Typography
        sx={{
          color: "#141718",
          fontWeight: "700",
          fontFamily: "Inter",
          fontSize: { xs: "20px", sm: "28px" },
          marginTop: { xs: "10px", sm: "20px" },
          marginBottom: { xs: "20px", sm: "40px" },
          lineHeight: "1.3",
        }}
      >
        Oops... looks like you got lost
      </Typography>

      <Button
        variant="contained"
        sx={{ textTransform: "capitalize" }}
        onClick={() => navigate("/")}
      >
        Get Back to Home Page
      </Button>
    </Box>
  );
};

export default PageNotFound;
