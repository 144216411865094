import { configureStore } from "@reduxjs/toolkit";
import {
  categoriesSlice,
  chatHistorySlice,
  customersSlice,
  datasetsSlice,
  faqSlice,
  modalSlice,
  tagsSlice,
} from "./features";

export const store = configureStore({
  reducer: {
    modal: modalSlice,
    tag: tagsSlice,
    category: categoriesSlice,
    customer: customersSlice,
    dataset: datasetsSlice,
    chatHistory: chatHistorySlice,
    faq: faqSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
