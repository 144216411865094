import AddIcon from "@mui/icons-material/Add";
import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import PrimaryButton from "../components/buttons/PrimaryButton";
import CategoriesList from "../components/categories/CategoriesList";
import ContentWrapper from "../components/common/ContentWrapper";
import HeaderWrapper from "../components/common/HeaderWrapper";
import CategoryModal from "../components/modals/CategoryModal";
import { saveModalName } from "../redux/features/modal/modalSlice";
import { CATEGORY_MODAL } from "../utils/constants";

const Categories: React.FC = () => {
  const dispatch = useDispatch();
  const handleUploadModal = () => {
    dispatch(saveModalName(CATEGORY_MODAL));
  };

  return (
    <ContentWrapper>
      <Box>
        <HeaderWrapper>
          <Heading variant="h5">Categories</Heading>
          <PrimaryButton
            text="Add Category"
            startIcon={<AddIcon />}
            onClick={handleUploadModal}
          />
        </HeaderWrapper>
        <CategoriesList />
        <CategoryModal />
      </Box>
    </ContentWrapper>
  );
};

export default Categories;

const Heading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    fontSize: "26px",
    marginBottom: "16px",
  },
}));
