const FaqIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9.50693C22 10.7392 17.8974 12 17.8974 12C17.8974 12 17.8132 10.7321 17.5 10C17.4225 9.81891 17.3101 9.62173 17.1884 9.43165C16.7862 8.80353 16.0914 8.44576 15.3506 8.35884C14.2864 8.23395 12.693 8.06219 11.5 8C9.35503 7.88818 6 8 6 8V4.87769C6 3.64616 6.92144 2.57385 8.22113 2.39462C10.1075 2.13462 12.0373 2 14 2C15.934 1.99943 17.8654 2.13132 19.7789 2.39462C21.0786 2.57385 22 3.64539 22 4.87769V9.50693Z"
        stroke="#54FFEA"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.30769 12.0833H13.6923M6.30769 14.4167H10M2 15.5911C2 16.8356 2.92144 17.9198 4.22113 18.101C5.14749 18.2301 6.08369 18.3289 7.02974 18.3958C7.31692 18.416 7.57949 18.5591 7.73949 18.7854L10 22L12.2605 18.7854C12.3398 18.6737 12.4449 18.5805 12.5679 18.5129C12.6909 18.4454 12.8285 18.4054 12.9703 18.3958C13.9099 18.3293 14.8467 18.231 15.7789 18.101C17.0786 17.9198 18 16.8363 18 15.5903V10.9097C18 9.66367 17.0786 8.58022 15.7789 8.399C13.8654 8.13278 11.934 7.99942 10 8C8.03733 8 6.10749 8.13611 4.22113 8.399C2.92144 8.58022 2 9.66445 2 10.9097V15.5903V15.5911Z"
        stroke="#54FFEA"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FaqIcon;
