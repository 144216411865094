import { Box } from "@mui/material";
import { FC } from "react";

interface IContentWrapper {
  children: React.ReactNode;
}

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  background: "#F8F8F8",
  width: "100%",
  maxWidth: "1400px",
  margin: "0 auto",
};
const ContentWrapper: FC<IContentWrapper> = ({ children }) => {
  return <Box sx={{ ...wrapperStyle }}>{children}</Box>;
};

export default ContentWrapper;
