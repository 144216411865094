import { Button, useTheme } from "@mui/material";
import React from "react";
interface ERROR_BUTTON_PROPS {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  size?: "small" | "medium" | "large" | undefined;
  fullWidth?: boolean;
}

const ErrorButton: React.FC<ERROR_BUTTON_PROPS> = (props) => {
  const { text, icon, type } = props;
  const theme = useTheme();
  const Wrapper = {
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  };
  return (
    <Button
      startIcon={icon}
      variant="contained"
      {...props}
      disableElevation
      type={type}
      disableRipple
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{ ...Wrapper, ...props.style }}
      size={props.size}
      fullWidth={props.fullWidth}
    >
      {text}
    </Button>
  );
};

export default ErrorButton;
