import { Box, styled } from "@mui/material";
import { FC } from "react";

interface IFormWrapper {
  children: React.ReactNode;
}
const FormWrapper: FC<IFormWrapper> = ({ children }) => {
  const BoxWrapper = styled(Box)(({ theme }) => ({
    // padding: "60px 100px",
    borderRadius: "20px",
    // margin: "20px",
    background: "#fff",
    transition: "0.3s all ease-in-out",
    minWidth: "600px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "30px 60px",
      minWidth: "600px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 30px",
      minWidth: "300px",
    },
  }));
  const Wrapper = styled(Box)(({ theme }) => ({
    maxWidth: "500px",
    width: "100%",
    boxSizing: "border-box",
  }));
  return (
    <BoxWrapper>
      <Wrapper>{children}</Wrapper>
    </BoxWrapper>
  );
};

export default FormWrapper;
