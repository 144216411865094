import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponse } from "../../../openapi";
import { ChatHistoryList } from "../../../openapi/models/chat-history-list";
import { ChatHistoryResponse } from "../../../openapi/models/chat-history-response";

export interface CustomerState {
  histories: Array<ChatHistoryResponse> | undefined;
  pagination: ChatHistoryList | undefined;
  threadedHistories: Array<ChatHistoryResponse> | undefined;
  threadedPagination: ChatHistoryList | undefined;
  isLoading: boolean;
  isSearched: boolean;
  searchFromList: UserResponse | undefined;
  historyDetails: ChatHistoryResponse | undefined;
  threadPage: number;
}

const initialState: CustomerState = {
  histories: undefined,
  pagination: undefined,
  isLoading: false,
  isSearched: false,
  searchFromList: undefined,
  historyDetails: undefined,
  threadedHistories: undefined,
  threadedPagination: undefined,
  threadPage: 1,
};

export const chatHistorySlice = createSlice({
  name: "histories",
  initialState,
  reducers: {
    storeChatHistories: (
      state,
      action: PayloadAction<Array<ChatHistoryResponse>>
    ) => {
      state.histories = action.payload;
    },

    storePaginationDetails: (state, action: PayloadAction<ChatHistoryList>) => {
      state.pagination = action.payload;
    },
    storeThreadedHistories: (
      state,
      action: PayloadAction<Array<ChatHistoryResponse>>
    ) => {
      state.threadedHistories = action.payload;
    },

    storeThreadPagination: (state, action: PayloadAction<ChatHistoryList>) => {
      state.threadedPagination = action.payload;
    },
    saveLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    saveSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearched = action.payload;
    },
    saveSearchFromList: (state, action: PayloadAction<UserResponse>) => {
      state.searchFromList = action.payload;
    },
    clearSearchfromList: (state) => {
      state.searchFromList = undefined;
    },
    storeHistoryDetails: (
      state,
      action: PayloadAction<ChatHistoryResponse>
    ) => {
      state.historyDetails = action.payload;
    },
    clearHistoryDetils: (state) => {
      state.historyDetails = undefined;
    },
    saveThreadPage: (state, action: PayloadAction<number>) => {
      state.threadPage = action.payload;
    },
  },
});

export const {
  storePaginationDetails,
  storeChatHistories,
  saveLoading,
  saveSearching,
  saveSearchFromList,
  clearSearchfromList,
  storeHistoryDetails,
  clearHistoryDetils,
  storeThreadPagination,
  storeThreadedHistories,
  saveThreadPage,
} = chatHistorySlice.actions;

export default chatHistorySlice.reducer;
